import { useEffect, useState } from 'react';
import type { DecoratedFileCollection } from '@witmetrics/api-client';
import { API } from '@/api';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import {
  useActiveAccount,
  useDispatch,
  useFileCollection,
} from '@/store/useStore';
import {
  addFileCollection as dispatchAddFileCollection,
  updateFileCollection as dispatchUpdateFileCollection,
} from '@/store/slices/fileCollectionsSlice';
import TextField from '@/components/TextField';
import { showSuccessToaster } from '@/utils/toasters';
import { classes, isDisabled, validateFields } from './utils';

type FileCollectionDialogProps = {
  parentFileCollectionID?: number;
  fileCollectionID?: number;
  onSave?: (fileCollection: DecoratedFileCollection) => void;
  onClose: () => void;
};

export default function FileCollectionDialog({
  parentFileCollectionID,
  fileCollectionID,
  onSave,
  onClose,
}: FileCollectionDialogProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const fileCollection = useFileCollection(fileCollectionID);

  useEffect(() => {
    if (fileCollection) fillFields();
  }, [fileCollection]);

  const fillFields = () => {
    setName(fileCollection!.name);
    setDescription(fileCollection!.description || '');
  };

  const handleCreateCollection = async () => {
    const collection = await API.FileCollections.createNewFileCollection({
      name,
      description,
      parentFileCollectionID,
      practiceID: activeAccount!.accountID,
    });
    const decoratedCollection = {
      ...collection,
      childFiles: 0,
      childFileCollections: 0,
    };
    dispatch(dispatchAddFileCollection(decoratedCollection));
    showSuccessToaster('File collection created');
    return decoratedCollection;
  };

  const handleUpdateCollection = async () => {
    const collection = await API.FileCollections.updateFileCollection(
      fileCollectionID!,
      {
        name,
        description,
      }
    );
    const decoratedCollection = {
      ...collection,
      childFiles: fileCollection!.childFiles,
      childFileCollections: fileCollection!.childFileCollections,
    };
    dispatch(dispatchUpdateFileCollection(decoratedCollection));
    showSuccessToaster('File collection updated');
    return decoratedCollection;
  };

  const handleSave = async () => {
    try {
      if (!activeAccount) return;
      toggleSaving(true);
      const invalid = validateFields(name);
      if (invalidFields.length > 0) return setInvalidFields(invalid);
      const collection = await (fileCollectionID
        ? handleUpdateCollection()
        : handleCreateCollection());
      toggleSaving(false);
      if (onSave) onSave(collection);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving file collection');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title={
          fileCollectionID ? 'Update file collection' : 'New file collection'
        }
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <TextField
          error={invalidFields.includes('name')}
          className={classes.input}
          name="name"
          label="Name"
          value={name}
          onChange={({ name }) => setName(name)}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          className={classes.input}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={({ description }) => setDescription(description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(name)}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
