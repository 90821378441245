import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import {
  addSequenceStep,
  deleteSequenceStep,
  setSequenceSteps,
  updateSequenceStep,
} from './sequenceStepsSlice';
import {
  DecoratedSequenceStepFile,
  UndecoratedSequenceStepFile,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type SequenceStepFilesState = {
  byID: Record<string, UndecoratedSequenceStepFile>;
  bySequenceStepID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  bySequenceStepID: {},
} satisfies SequenceStepFilesState as SequenceStepFilesState;

export const sequenceStepFilesSlice = createSlice({
  name: 'sequenceStepFiles',
  initialState,
  reducers: {
    setSequenceStepFiles: (
      state,
      action: PayloadAction<DecoratedSequenceStepFile[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addSequenceStepFile: (
      state,
      action: PayloadAction<DecoratedSequenceStepFile>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteSequenceStepFile: (
      state,
      action: PayloadAction<{ sequenceStepID: number; fileID: number }>
    ) => {
      const key = buildSequenceStepFileKey(
        action.payload.sequenceStepID,
        action.payload.fileID
      );
      if (!state.byID[key]) return state;
      const sequenceStepID = action.payload.sequenceStepID;
      if (state.bySequenceStepID[sequenceStepID]) {
        state.bySequenceStepID[sequenceStepID] = state.bySequenceStepID[
          sequenceStepID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, bySequenceStepID: {} };
      })
      .addCase(setSequenceSteps, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap(({ files }) => files)
        );
      })
      .addCase(addSequenceStep, (state, action) => {
        return getNextState(state, action.payload.files);
      })
      .addCase(updateSequenceStep, (state, action) => {
        return getNextState(state, action.payload.files);
      })
      .addCase(deleteSequenceStep, (state, action) => {
        const sequenceStepID = action.payload.stepID;
        const keys = state.bySequenceStepID[sequenceStepID] || [];
        keys.forEach((key) => {
          delete state.byID[key];
        });
        delete state.bySequenceStepID[sequenceStepID];
      })
      .addDefaultCase(() => {});
  },
});

function formatSequenceStepFile({
  file,
  ...sequenceStepFile
}: DecoratedSequenceStepFile) {
  return sequenceStepFile;
}

function getNextState(
  state: SequenceStepFilesState,
  sequenceStepFiles: DecoratedSequenceStepFile[]
) {
  let byID = { ...state.byID };
  let bySequenceStepID = { ...state.bySequenceStepID };
  sequenceStepFiles.forEach((ssf) => {
    const key = buildSequenceStepFileKey(ssf.sequenceStepID, ssf.fileID);
    byID[key] = formatSequenceStepFile(ssf);
    bySequenceStepID[ssf.sequenceStepID] = addToIDArray(
      bySequenceStepID[ssf.sequenceStepID],
      key
    );
  });
  return { byID, bySequenceStepID };
}

export function buildSequenceStepFileKey(
  sequenceStepID: number,
  fileID: number
) {
  return `${sequenceStepID}.${fileID}`;
}

export const {
  setSequenceStepFiles,
  addSequenceStepFile,
  deleteSequenceStepFile,
} = sequenceStepFilesSlice.actions;

export default sequenceStepFilesSlice.reducer;
