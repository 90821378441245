import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
} from '@witmetrics/api-client';
import FileTree from './FileTree';
import Scene from '@/components/Scene';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { mergeWithoutDuplicates } from '@/utils/arrays';
import { useScene } from '@/providers/SceneProvider';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { checkOpenFileCollection } from '@/utils/files';
import { centerObjects } from '@/utils/scans';
import ImageViewer from '@/components/ImageViewer';
import TabsBlock, { Tab, type TabsBlockProps } from '@/components/TabsBlock';
import VideoViewer from '@/components/VideoViewer';
import PdfViewer from '@/components/PdfViewer';
import {
  classes,
  getLoadedImages,
  getLoadedPdfs,
  getLoadedScans,
  getLoadedVideos,
  hasMedia,
  hasOnlyImage,
  hasOnlyPdf,
  hasOnlyVideo,
  LoadedMedia,
} from './utils';

export type ViewerContentProps = {
  isLoading: boolean;
  fileCollectionID: number;
  fileCollections: DecoratedFileCollection[];
  files: DecoratedFileCollectionFile[];
  onOpenFiles: (fileIDs: number[]) => void;
};

export default function ViewerContent({
  isLoading,
  fileCollectionID,
  fileCollections,
  files,
  onOpenFiles,
}: ViewerContentProps) {
  const { loadedFiles } = useLoadedFiles();
  const { addToScene, clearScene } = useScene();
  const [openFileIDs, setOpenFileIDs] = useState<number[]>([]);

  useEffect(() => {
    handleUpdateScene();
  }, [openFileIDs, loadedFiles]);

  const handleUpdateScene = () => {
    const loadedScans = getLoadedScans(openFileIDs, loadedFiles);
    if (loadedScans.length === 0) return;
    addToScene([centerObjects(loadedScans)], true);
  };

  const handleOpenFile = async (fileID: number) => {
    if (openFileIDs.includes(fileID)) {
      const nextIDs = openFileIDs.filter((id) => id !== fileID);
      if (nextIDs.length === 0) clearScene();
      setOpenFileIDs(nextIDs);
    } else {
      onOpenFiles([fileID]);
      setOpenFileIDs(mergeWithoutDuplicates(openFileIDs, [fileID]));
    }
  };

  const handleOpenCollection = async (fileCollectionID: number) => {
    // TODO: Traverse sub-collections
    const fileIDs = files
      .filter((f) => f.fileCollectionID === fileCollectionID)
      .map((f) => f.fileID);
    const { isAllOpen } = checkOpenFileCollection(
      files,
      openFileIDs,
      fileCollectionID
    );
    let nextIDs: number[];
    if (isAllOpen) {
      // Clear it out
      nextIDs = openFileIDs.filter((id) => !fileIDs.includes(id));
    } else {
      // Select it all
      onOpenFiles(fileIDs);
      nextIDs = mergeWithoutDuplicates(openFileIDs, fileIDs);
    }
    if (nextIDs.length === 0) clearScene();
    setOpenFileIDs(nextIDs);
  };

  const renderMessage = () => {
    if (isLoading) {
      return (
        <div className={classes.message}>
          Loading file(s)
          <LoopingEllipsis />
        </div>
      );
    } else if (openFileIDs.length === 0) {
      return <div className={classes.message}>Select File(s)</div>;
    } else return null;
  };

  const renderTabsBlock = (tabs: TabsBlockProps<any>['children']) => {
    return (
      <TabsBlock
        className={classes.tabsBlock}
        bodyClassName={classes.tabsContent}
        name="Viewer">
        {tabs}
      </TabsBlock>
    );
  };

  const buildMediaTabs = (
    loadedImages: LoadedMedia[],
    loadedVideos: LoadedMedia[],
    loadedPdfs: LoadedMedia[]
  ) => {
    return [
      ...loadedImages.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <ImageViewer key={id} src={src} />
        </Tab>
      )),
      ...loadedVideos.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <VideoViewer key={id} src={src} />
        </Tab>
      )),
      ...loadedPdfs.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <PdfViewer key={id} src={src} />
        </Tab>
      )),
    ];
  };

  const renderViewer = () => {
    if (isLoading) return null;
    const loadedScans = getLoadedScans(openFileIDs, loadedFiles);
    const loadedImages = getLoadedImages(openFileIDs, loadedFiles, files);
    const loadedPdfs = getLoadedPdfs(openFileIDs, loadedFiles, files);
    const loadedVideos = getLoadedVideos(openFileIDs, loadedFiles, files);
    const hasMediaContent = hasMedia(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyImage = hasOnlyImage(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyPdf = hasOnlyPdf(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyVideo = hasOnlyVideo(loadedImages, loadedPdfs, loadedVideos);
    if (loadedScans.length > 0 && hasMediaContent) {
      return renderTabsBlock([
        <Tab
          key="scans"
          className={classes.tabsContent}
          name="SCANS"
          label="Scans">
          <Scene className={classes.scene} />
        </Tab>,
        ...buildMediaTabs(loadedImages, loadedVideos, loadedPdfs),
      ]);
    } else if (loadedScans.length > 0 || openFileIDs.length === 0) {
      return (
        <Scene
          className={twMerge(
            classes.scene,
            openFileIDs.length === 0 && classes.hiddenScene
          )}
        />
      );
    } else if (isOnlyImage) {
      return (
        <div className={classes.media}>
          <ImageViewer src={loadedImages[0].src} />
        </div>
      );
    } else if (isOnlyPdf) {
      return (
        <div className={classes.media}>
          <PdfViewer src={loadedPdfs[0].src} />
        </div>
      );
    } else if (isOnlyVideo) {
      return (
        <div className={classes.media}>
          <VideoViewer src={loadedVideos[0].src} />
        </div>
      );
    } else if (hasMediaContent) {
      return renderTabsBlock(
        buildMediaTabs(loadedImages, loadedVideos, loadedPdfs)
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <FileTree
        className={classes.fileTree}
        parentFileCollectionID={fileCollectionID}
        openFileIDs={openFileIDs}
        fileCollections={fileCollections}
        files={files}
        onOpenFile={handleOpenFile}
        onOpenCollection={handleOpenCollection}
      />
      <div className={classes.viewerWrapper}>
        {renderMessage()}
        {renderViewer()}
      </div>
    </div>
  );
}
