import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddFileIcon from '@/icons/AddFileIcon';
import AddFolderIcon from '@/icons/AddFolderIcon';
import FileInput from '@/components/FileInput';
import DirectoryInput from '@/components/DirectoryInput';
import { type Directory } from '@/utils/files';
import DefaultOptionsMenu, {
  type DefaultOptionsMenuProps,
} from '@/components/DefaultOptionsMenu';
import { classes } from './utils';

export type OptionsMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  anchorOrigin?: DefaultOptionsMenuProps['anchorOrigin'];
  transformOrigin?: DefaultOptionsMenuProps['transformOrigin'];
  onSelectFiles: (files: File[]) => void;
  onSelectDirectory: (directory: Directory) => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export default function OptionsMenu({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onSelectFiles,
  onSelectDirectory,
  onEdit,
  onDelete,
  onClose,
}: OptionsMenuProps) {
  return (
    <DefaultOptionsMenu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onEdit={onEdit}
      onDelete={onDelete}
      onClose={onClose}
      extraOptions={
        <>
          <FileInput multiple id="new-files" onSelect={onSelectFiles} />
          <DirectoryInput
            multiple
            id="new-directories"
            onSelect={onSelectDirectory}
          />
          <li className={classes.menuItem}>
            <label htmlFor="new-files" className={classes.inputLabel}>
              <ListItemIcon>
                <AddFileIcon />
              </ListItemIcon>
              <ListItemText>Upload File(s)</ListItemText>
            </label>
          </li>
          <li className={classes.menuItem}>
            <label htmlFor="new-directories" className={classes.inputLabel}>
              <ListItemIcon>
                <AddFolderIcon />
              </ListItemIcon>
              <ListItemText>Upload File Collection(s)</ListItemText>
            </label>
          </li>
        </>
      }
    />
  );
}
