import { type DecoratedFileCollection } from '@witmetrics/api-client';
import DirectoryBlock from '@/components/DirectoryBlock';
import FolderIcon from '@/icons/FolderIcon';
import { getCountLabel } from './utils';

export type CollectionBlockProps = {
  active?: boolean;
  fileCollection: DecoratedFileCollection;
  onSelect: (fileID: number) => void;
  onOpen: (fileID: number) => void;
};

export default function CollectionBlock({
  active,
  fileCollection,
  onSelect,
  onOpen,
}: CollectionBlockProps) {
  return (
    <DirectoryBlock
      active={active}
      name={fileCollection.name}
      description={fileCollection.description}
      label={getCountLabel(fileCollection)}
      updatedAt={fileCollection.updatedAt}
      renderIcon={(iconClassName) => <FolderIcon className={iconClassName} />}
      onSelect={() => onSelect(fileCollection.id)}
      onOpen={() => onOpen(fileCollection.id)}
    />
  );
}
