'use client';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DecoratedSharedContentWithFile } from '@witmetrics/api-client';
import Dialog, { DialogBody, DialogTitle } from '@/components/Dialog';
import { useFile } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import Toggle from '@/components/Toggle';
import TextField from '@/components/TextField';
import IconButton from '@/components/IconButton';
import QRCodeIcon from '@/icons/QRCodeIcon';
import CopyIcon from '@/icons/CopyIcon';
import { useAppState } from '@/providers/AppStateProvider';
import { getUnisonURL } from '@/api/getURL';
import ShareLinkDialog from '@/dialogs/ShareLinkDialog';
import { copyToClipboard } from '@/utils/clipboard';
import { showSuccessToaster } from '@/utils/toasters';
import {
  classes,
  createSharedContent,
  fetchSharedContent,
  updateSharedContent,
} from './utils';

export type ShareFileDialogProps = {
  fileID: number;
  onClose: () => void;
};

export default function ShareFileDialog({
  fileID,
  onClose,
}: ShareFileDialogProps) {
  const { onApiError } = useAppState();
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [sharedContent, setSharedContent] =
    useState<DecoratedSharedContentWithFile | null>(null);
  const [isShareLinkDialogOpen, toggleShareLinkDialog] = useToggle(false);
  const file = useFile(fileID);

  const isActive = !!sharedContent && sharedContent.enabled;

  useEffect(() => {
    handleFetchSharing();
  }, [file]);

  const handleFetchSharing = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchSharedContent(fileID);
      setSharedContent(data);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching shared content');
      throw err;
    }
  };

  const handleToggleSharing = async (enabled: boolean) => {
    if (sharedContent) {
      // Just need to update it
      await updateSharedContent(sharedContent.id, enabled);
      setSharedContent({ ...sharedContent, enabled });
    } else {
      // Need to create it, defaults to unlocked & enabled
      const newSharedContent = await createSharedContent(fileID);
      setSharedContent(newSharedContent as DecoratedSharedContentWithFile);
    }
  };

  const getShareLink = () => {
    return `${getUnisonURL()}/shared/${sharedContent?.slug || ''}`;
  };

  const handleCopy = async () => {
    await copyToClipboard(getShareLink());
    showSuccessToaster(`Copied to clipboard`);
  };

  const renderToggleLabel = () => {
    return isActive ? (
      <>
        <p className={classes.message}>
          Sharing is currently enabled for this file.
        </p>
        <p>
          Turning off sharing will disable previously shared links until
          re-enabled.
        </p>
      </>
    ) : (
      <>
        <p className={classes.message}>
          Sharing is currently disabled for this file.
        </p>
        <p>To share with a colleague, sharing must be enabled.</p>
      </>
    );
  };

  return (
    <>
      {isShareLinkDialogOpen && (
        <ShareLinkDialog
          link={getShareLink()}
          onClose={() => toggleShareLinkDialog(false)}
        />
      )}
      <Dialog className={classes.dialog} onClose={onClose}>
        <DialogTitle title="Share" onClose={onClose} />
        <DialogBody className={classes.body}>
          <div className={twMerge(classes.section, classes.sharingSection)}>
            <Toggle
              active={isActive}
              className={classes.toggle}
              onChange={handleToggleSharing}
            />
            <div>{renderToggleLabel()}</div>
          </div>
          {isActive && (
            <div className={twMerge(classes.section, classes.linkSection)}>
              <TextField
                className={classes.linkInput}
                name="share-link"
                label="Share link"
                value={getShareLink()}
                onChange={() => null}
              />
              <div className={classes.linkSection}>
                <IconButton
                  title="View QR code"
                  onClick={() => toggleShareLinkDialog(true)}>
                  <QRCodeIcon />
                </IconButton>
                <IconButton title="Copy link" onClick={handleCopy}>
                  <CopyIcon />
                </IconButton>
              </div>
            </div>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
