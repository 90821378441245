import FileInput from '@/components/FileInput';
import Tooltip from '@/components/Tooltip';
import AddIcon from '@/icons/AddIcon';
import { classes } from './utils';

export type StepFilesButtonProps = {
  position: string;
  onSelectFiles: (files: File[], position: string) => void;
};

export default function StepFilesButton({
  position,
  onSelectFiles,
}: StepFilesButtonProps) {
  return (
    <>
      <FileInput
        multiple
        id={`new-sequence-files-${position}`}
        onSelect={(files) => onSelectFiles(files, position)}
      />
      <Tooltip title="Add File(s)">
        <label htmlFor={`new-sequence-files-${position}`}>
          <AddIcon className={classes.iconButton} />
        </label>
      </Tooltip>
    </>
  );
}
