import { shadows, theme } from '@/utils/theme';

export const styles = {
  slider: {
    color: theme.palette['purple-2'].main,
    height: 10,
    marginY: '1rem',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-mark': {
      height: 6,
      width: 6,
      borderRadius: '50%',
    },
    '& .MuiSlider-mark:nth-child(1 of .MuiSlider-mark) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */':
      {
        transform: 'translate(2px, -50%)', // Give it a little space from the edge
      },
    '& .MuiSlider-mark:nth-last-child(1 of .MuiSlider-mark)': {
      transform: 'translate(-9px, -50%)', // Give it a little space from the edge
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: theme.palette['white'].main,
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: '1.25rem', // text-sm
      fontSize: '0.875rem', // text-sm
      boxShadow: shadows['card'],
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: theme.palette['purple-2'].main,
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  },
};
