import type { DecoratedSequenceStep } from '@witmetrics/api-client';
import Slider, { type SliderProps } from '@/components/Slider';
import IconButton from '@/components/IconButton';
import AddIcon from '@/icons/AddIcon';
import SequenceStepDialog from '@/dialogs/SequenceStepDialog';
import { useSequenceSteps, useStep } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { showErrorToaster } from '@/utils/toasters';
import { classes } from './utils';

export type SequenceSliderProps = {
  sequenceID: number;
  activeStepID: number | null;
  stepCount: number;
  onSelect: (stepID: number) => void;
  onAddStep: (step: DecoratedSequenceStep) => void;
};

export default function SequenceSlider({
  sequenceID,
  activeStepID,
  stepCount,
  onSelect,
  onAddStep,
}: SequenceSliderProps) {
  const activeStep = useStep(activeStepID);
  const steps = useSequenceSteps(sequenceID);
  const [isNewStepDialogShown, toggleNewStepDialog] = useToggle(false);

  const handleChange: SliderProps['onChange'] = (e, val) => {
    let index = Array.isArray(val) ? val[0] : val; // It'll never be an array, but MuiSlider types it as number[] | number
    const step = steps.find((s) => s.position === index - 1); // 0-indexed
    if (!step) {
      showErrorToaster('Step not found');
      throw Error('Step not found');
    }
    return onSelect(step.id);
  };

  if (!activeStep) return null; // TODO

  return (
    <>
      <div className={classes.wrapper}>
        <Slider
          max={stepCount}
          value={activeStep.position + 1}
          onChange={handleChange}
        />
        <div className={classes.addWrapper}>
          <IconButton
            className={classes.addButton}
            title="Add step"
            titlePosition="top"
            onClick={() => toggleNewStepDialog()}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      {isNewStepDialogShown && (
        <SequenceStepDialog
          sequenceID={sequenceID}
          onClose={toggleNewStepDialog}
          onSave={onAddStep}
        />
      )}
    </>
  );
}
