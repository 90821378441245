import { type PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export interface TitleProps extends PropsWithChildren {
  size?: 'xs' | 'sm' | 'lg';
  className?: string;
}

export default function Title({
  className = '',
  size = 'lg',
  ...props
}: TitleProps) {
  return (
    <h1
      className={twMerge(classes.title, classes[size], className)}
      {...props}
    />
  );
}
