export const FILE_PREVIEW = 'FILE_PREVIEW';
export const COLLECTION_PREVIEW = 'COLLECTION_PREVIEW';
export const SEQUENCE_PREVIEW = 'SEQUENCE_PREVIEW';
export const EDIT_FILE = 'EDIT_FILE';
export const EDIT_SEQUENCE = 'EDIT_SEQUENCE';
export const EDIT_COLLECTION = 'EDIT_COLLECTION';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_SEQUENCE = 'DELETE_SEQUENCE';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const SHARE_FILE = 'SHARE_FILE';

export const FILE_DIALOGS = {
  FILE_PREVIEW,
  COLLECTION_PREVIEW,
  SEQUENCE_PREVIEW,
  EDIT_FILE,
  EDIT_SEQUENCE,
  EDIT_COLLECTION,
  DELETE_FILE,
  DELETE_COLLECTION,
  SHARE_FILE,
} as const;
