import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Crumb from './Crumb';
import { type BreadcrumbChild } from './utils';

export { Crumb, type BreadcrumbChild };

export type BreadcrumbsProps<T> = {
  className?: string;
  children: BreadcrumbChild<T>[];
  onChange: (update: T) => void;
};

export default function Breadcrumbs<T>({
  className = '',
  children,
  onChange,
}: BreadcrumbsProps<T>) {
  const renderChild = (child: BreadcrumbChild<T>, i: number) => {
    const { label, value } = child.props;
    if (i === children.length - 1) {
      return <div key={label}>{label}</div>;
    } else {
      return (
        <button
          key={label}
          className="hover:underline"
          onClick={() => onChange(value)}>
          {label}
        </button>
      );
    }
  };

  return (
    <MuiBreadcrumbs className={className}>
      {children.map(renderChild)}
    </MuiBreadcrumbs>
  );
}
