import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import FileTypeDropdown from './FileTypeDropdown';
import { FILE_TYPES, type FileType } from '@/constants/fileTypes';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type FileTypeSelectorProps = {
  className?: string;
  disabled?: boolean;
  fileType: FileType;
  onChange: (fileType: FileType) => void;
};

export default function FileTypeSelector({
  disabled,
  className,
  fileType,
  onChange,
}: FileTypeSelectorProps) {
  const menuToggleRef = useRef(null);
  const [isMenuShown, toggleMenu] = useToggle(false);
  return (
    <>
      <button
        ref={menuToggleRef}
        className={twMerge(
          classes.button,
          className,
          disabled && classes.disabled
        )}
        onClick={() => !disabled && toggleMenu()}>
        {FILE_TYPES[fileType].label}
      </button>
      {isMenuShown && (
        <FileTypeDropdown
          open={isMenuShown}
          anchorTo={menuToggleRef.current}
          onClose={() => toggleMenu()}
          onChange={onChange}
        />
      )}
    </>
  );
}
