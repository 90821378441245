import { TREE_ITEM_TYPES } from '@/constants/treeItemTypes';
import type { TreeItem } from '@/types/trees';

export const classes = {
  message: 'text-lg text-grey-7 py-8 text-center',
};

export type DndTreeItem = {
  id: string;
  parent: string;
  text: string;
  droppable: boolean;
  data: {
    type: keyof typeof TREE_ITEM_TYPES;
  };
};

export function buildTreeItems(tree: TreeItem[]): DndTreeItem[] {
  return tree.map((item) => ({
    id: item.id,
    parent: item.parentID || 'root',
    text: item.name,
    droppable: item.type === TREE_ITEM_TYPES.FILE_COLLECTION,
    data: {
      type: item.type,
    },
  }));
}
