import { truncateString } from '@/utils/strings';

export const classes = {
  wrapper:
    'w-64 h-52 rounded-lg bg-grey-2 flex flex-col justify-between cursor-pointer',
  active: 'bg-grey-4 shadow-sm',
  header: 'flex justify-between items-center pl-3 pr-2 pt-3 w-full',
  name: 'text-grey-8 font-medium text-sm select-none',
  optionsIcon: 'cursor-pointer',
  iconWrapper:
    'rounded-xl bg-grey-1 border border-grey-3 flex flex-col items-center justify-center py-6 px-4 mx-3 my-3',
  fileIcon: 'text-4xl text-grey-5 select-none',
  childCount: 'text-grey-7 text-sm mt-2 select-none',
  footer: 'text-xs text-grey-7 px-4 pt-1 pb-3 select-none',
};

export function getNameString(name: string) {
  const label = truncateString(name, 28);
  return { label, isTruncated: name.length > label.length };
}
