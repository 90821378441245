import { type DragEventHandler } from 'react';
import type { DecoratedFileCollection } from '@witmetrics/api-client';
import { useActiveAccount } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import {
  createFileCollection,
  type Directory,
  parseDroppedItems,
  uploadFile,
} from '@/utils/files';
import Dialog from '@mui/material/Dialog';
import UploadIcon from '@/icons/UploadIcon';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { useToggle } from '@/hooks/useToggle';
import type { UploadedFile } from '@/types/files';
import { classes } from './utils';

export type FileDropOverlayProps = {
  open: boolean;
  disabled?: boolean;
  parentFileCollectionID?: number;
  onClose: () => void;
  onSaveFiles: (
    uploadedFiles: UploadedFile[],
    parentCollectionID?: number
  ) => void;
  onSaveFileCollection: (fileCollection: DecoratedFileCollection) => void;
};

export default function FileDropOverlay({
  open,
  disabled,
  parentFileCollectionID,
  onSaveFiles,
  onSaveFileCollection,
  onClose,
}: FileDropOverlayProps) {
  const { onApiError, onToggleUnloadWarning } = useAppState();
  const activeAccount = useActiveAccount();
  const [isUploading, toggleUploading] = useToggle(false);

  const preventDragAction: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    if (disabled || isUploading) return;
    const directory = await parseDroppedItems(e.dataTransfer.items);
    toggleUploading(true);
    onToggleUnloadWarning(true);
    await Promise.all(
      Object.keys(directory).map((key) =>
        uploadDirectory(key, directory[key], parentFileCollectionID)
      )
    );
    toggleUploading(false);
    onToggleUnloadWarning(false);
    onClose();
  };

  // TODO: Why is the return value set as any?
  // TODO: Can this and the version in NewFileMenuButton be abstracted?
  const uploadDirectory: any = async (
    name: string,
    contents: File | Directory,
    parentCollectionID?: number
  ) => {
    try {
      if (contents instanceof File) {
        return handleUploadFile(contents, parentCollectionID);
      } else {
        const fileCollection = await handleCreateFileCollection(
          name,
          contents,
          parentCollectionID
        );
        return Object.keys(contents).forEach((key) =>
          uploadDirectory(key, contents[key], fileCollection.id)
        );
      }
    } catch (err) {
      toggleUploading(false);
      onApiError(err, 'Error uploading directory');
    }
  };

  const handleUploadFile = async (file: File, parentCollectionID?: number) => {
    const data = await uploadFile(file, activeAccount!.accountID);
    onSaveFiles([data], parentCollectionID);
    return data;
  };

  const handleCreateFileCollection = async (
    name: string,
    directory: Directory,
    parentCollectionID?: number
  ) => {
    const fileCollection = await createFileCollection(
      name,
      directory,
      activeAccount!.accountID,
      parentCollectionID
    );
    onSaveFileCollection(fileCollection);
    return fileCollection;
  };

  if (!open) return null;

  return (
    <Dialog
      open
      PaperProps={{
        className: classes.dialog,
      }}
      slotProps={{
        root: {
          onDragOver: preventDragAction,
          onDrop: handleDrop,
        },
      }}
      onClose={onClose}
      onDragOver={preventDragAction}
      onDrop={preventDragAction}>
      <div className={classes.wrapper}>
        <UploadIcon className={classes.uploadIcon} />
        <div className={classes.message}>
          {isUploading ? (
            <>
              Uploading file(s)
              <LoopingEllipsis />
            </>
          ) : (
            'Drag & drop your file(s)'
          )}
        </div>
      </div>
    </Dialog>
  );
}
