import LinearProgress from '@mui/material/LinearProgress';
import { styles } from './utils';

export type ProgressBarProps = {
  progress: number;
  className?: string;
};

export default function ProgressBar({
  progress,
  className = '',
}: ProgressBarProps) {
  return (
    <LinearProgress
      className={className}
      sx={styles.progressBar}
      variant="determinate"
      value={progress}
    />
  );
}
