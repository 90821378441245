import { uploadFileToFileCollection } from '@/utils/files';
import { mapToKey } from '@/utils/arrays';

export async function uploadFiles(
  accountID: number,
  fileCollectionID: number,
  files: File[]
) {
  const data = await Promise.all(
    files.map((file) =>
      uploadFileToFileCollection(accountID, fileCollectionID, file)
    )
  );
  return {
    files: mapToKey(data, 'file'),
    fileCollectionFiles: mapToKey(data, 'fileCollectionFile'),
    fileVersions: mapToKey(data, 'fileVersion'),
  };
}
