import type { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import Tab, { type TabProps } from './Tab';

export type TabChild = ReactElement<TabProps, typeof Tab>;

export const classes = {
  label:
    'pt-4 pb-2 px-3 inline-block text-base ease-in duration-100 select-none',
  bar: 'h-0 absolute bottom-[-1px] border-b-[2px] border-purple-1 transition-[left, width] duration-200 ease-in-out',
  activeTab: 'text-purple-2 font-medium',
  inactiveTab: 'text-grey-6 font-normal',
  enabled: 'cursor-pointer',
  disabled: 'cursor-not-allowed',
  labelsWrapper: 'relative border-b border-grey-4',
};

export const styles = {
  label: {
    transitionProperty: 'color',
  },
};

export function getMargins(tabs: any[], i: number) {
  if (i === 0) return 'mr-1';
  else if (i === tabs.length - 1) return 'ml-1';
  else return 'mx-1';
}

export function getInitialTab(tabs: TabChild[], defaultTab?: string): string {
  // If an invalid tab name is provided, default to the first tab
  const tabNames = getTabNames(tabs);
  if (!defaultTab || !tabNames.includes(defaultTab)) return tabNames[0];
  return defaultTab;
}

export function getActiveElementSizing(blockName: string) {
  const el: HTMLDivElement = document.querySelector(
    `.${blockName}-active-tab`
  )!;
  return el
    ? { left: `${el.offsetLeft}px`, width: `${el.clientWidth}px` }
    : null;
}

export function getTabClasses(
  tab: TabChild,
  index: number,
  children: TabChild[],
  name: string,
  activeTab: string
) {
  return twMerge(
    classes.label,
    getMargins(children, index),
    tab.props.disabled ? classes.disabled : classes.enabled,
    activeTab === tab.props.name
      ? `${classes.activeTab} ${name}-active-tab`
      : classes.inactiveTab
  );
}

export function isTabChanged(
  tabs: TabChild[],
  activeTab: string,
  defaultTab: string
) {
  return activeTab !== defaultTab && getTabNames(tabs).includes(defaultTab);
}

function getTabNames(tabs: TabChild[]) {
  return tabs.map((tab) => tab.props.name);
}
