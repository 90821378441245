import { type UndecoratedFile } from '@witmetrics/api-client';
import DirectoryBlock from '@/components/DirectoryBlock';
import FileIcon from '@/icons/FileIcon';

export type FileBlockProps = {
  active?: boolean;
  file: UndecoratedFile;
  onSelect: (fileID: number) => void;
  onOpen: (fileID: number) => void;
};

export default function FileBlock({
  active,
  file,
  onSelect,
  onOpen,
}: FileBlockProps) {
  return (
    <DirectoryBlock
      active={active}
      name={file.name}
      description={file.description}
      updatedAt={file.updatedAt}
      renderIcon={(iconClassName) => <FileIcon className={iconClassName} />}
      onSelect={() => onSelect(file.id)}
      onOpen={() => onOpen(file.id)}
    />
  );
}
