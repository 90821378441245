import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  input: 'w-full my-4',
};

export function validateFields(name: string) {
  let fields: string[] = [];
  if (isBlank(name)) fields.push('name');
  return fields;
}

export function isDisabled(name: string) {
  return isBlank(name);
}

export function splitName(name: string) {
  const parts = name.split('.');
  if (parts.length === 1) {
    return { name: parts[0], extension: '' };
  } else {
    return {
      name: parts.slice(0, -1).join('.'),
      extension: parts[parts.length - 1],
    };
  }
}

export function formatExtension(extension: string) {
  return extension.length > 0 ? `.${extension}` : '';
}
