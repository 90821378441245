import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export type ToggleProps = {
  className?: string;
  active: boolean;
  onChange: (active: boolean) => void;
};

export default function Toggle({ className, active, onChange }: ToggleProps) {
  return (
    <div
      className={twMerge(
        classes.wrapper,
        active ? classes.activeWrapper : classes.inactiveWrapper,
        className
      )}
      onClick={() => onChange(!active)}>
      <div
        className={twMerge(
          classes.ball,
          active ? classes.activeBall : classes.inactiveBall
        )}
      />
    </div>
  );
}
