import { twMerge } from 'tailwind-merge';
import { Draggable } from 'react-smooth-dnd';
import CloseIcon from '@/icons/CloseIcon';
import DragIcon from '@/icons/DragIcon';
import CheckIcon from '@/icons/CheckIcon';
import ErrorIcon from '@/icons/ErrorIcon';
import Spinner from '@/components/Spinner';
import FileTypeSelector from '@/components/FileTypeSelector';
import type { SelectedFile } from '../../utils';
import { type FileType } from '@/constants/fileTypes';
import { UPLOAD_STATUSES } from '@/constants/uploadStatuses';
import { classes } from './utils';

const { IN_PROGRESS, DONE, ERROR } = UPLOAD_STATUSES;

export type ListItemProps = {
  isSaving: boolean;
  selectedFile: SelectedFile;
  onChange: (selectedFile: SelectedFile) => void;
  onRemove: (selectedFile: SelectedFile) => void;
};

export default function ListItem({
  isSaving,
  selectedFile,
  onChange,
  onRemove,
}: ListItemProps) {
  const handleChangeType = (fileType: FileType) => {
    if (!isSaving) onChange({ ...selectedFile, fileType });
  };

  const handleRemove = () => {
    if (!isSaving) onRemove(selectedFile);
  };

  const renderLeftIcon = () => {
    if (selectedFile.uploadStatus === IN_PROGRESS) {
      return <Spinner size={20} className={classes.leftIcon} />;
    }
    if (selectedFile.uploadStatus === DONE) {
      return (
        <CheckIcon className={twMerge(classes.leftIcon, classes.checkIcon)} />
      );
    }
    if (selectedFile.uploadStatus === ERROR) {
      return (
        <ErrorIcon className={twMerge(classes.leftIcon, classes.errorIcon)} />
      );
    }
    return <DragIcon className={twMerge(classes.leftIcon, classes.dragIcon)} />;
  };

  return (
    /* @ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93 */
    <Draggable>
      <li className={classes.listItem}>
        <div className={classes.nameWrapper}>
          {renderLeftIcon()}
          <div className={classes.name}>{selectedFile.file.name}</div>
        </div>
        <div className={classes.options}>
          {selectedFile.fileType && (
            <FileTypeSelector
              disabled={isSaving}
              className={classes.fileTypeSelector}
              fileType={selectedFile.fileType}
              onChange={handleChangeType}
            />
          )}
          <CloseIcon
            className={twMerge(classes.closeIcon, isSaving && classes.disabled)}
            onClick={handleRemove}
          />
        </div>
      </li>
    </Draggable>
  );
}
