import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { type UndecoratedSequence } from '@witmetrics/api-client';
import Title from '@/components/Title';
import NewFileMenuButton from '@/components/NewFileMenuButton';
import ExpandableSearch from '@/components/ExpandableSearch';
import SequenceViewerDialog from '@/dialogs/SequenceViewerDialog';
import { classes } from './utils';

export type FileCollectionHeaderProps = {
  isFetchingData: boolean;
  fileCollectionID?: number;
  search: string;
  onSearch: (search: string) => void;
};

export default function FileCollectionHeader({
  isFetchingData,
  fileCollectionID,
  search,
  onSearch,
}: FileCollectionHeaderProps) {
  const [activeSequenceID, setActiveSequenceID] = useState<number | null>(null);

  const handleSearchUpdate = (val: string) => onSearch(val);

  const handleSelectSequence = (sequence: UndecoratedSequence) => {
    setActiveSequenceID(sequence.id);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.titlesWrapper}>
          <Title>Files</Title>
        </div>
        <div
          className={twMerge(
            classes.buttonsWrapper,
            isFetchingData && classes.disabled
          )}>
          <ExpandableSearch
            className={classes.search}
            value={search}
            onChange={handleSearchUpdate}
            onClose={() => handleSearchUpdate('')}
          />
          <NewFileMenuButton
            parentFileCollectionID={fileCollectionID}
            onSaveSequence={handleSelectSequence}
          />
        </div>
      </div>
      {activeSequenceID && (
        <SequenceViewerDialog
          sequenceID={activeSequenceID}
          onClose={() => setActiveSequenceID(null)}
        />
      )}
    </>
  );
}
