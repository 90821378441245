import type {
  DecoratedFileCollection,
  DecoratedFileCollectionFile,
  DecoratedSequenceStep,
  UndecoratedSequenceStep,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { SORT_ORDER } from '@/constants/sortOrder';
import { fetchFileCollectionData } from '@/utils/files';
import { mapToKey, sortByKey } from '@/utils/arrays';

export const classes = {
  dialog:
    'px-0 py-0 h-[90vh] w-[90vw] max-w-[1200px] max-h-[1000px] flex flex-col',
  viewer: 'grow flex justify-start',
  progressWrapper: 'flex flex-col items-center justify-center grow px-12',
  progressLabel: 'text-lg text-grey-7 mb-2',
  progressBar: 'w-full mt-6',
};

export async function fetchSequenceSteps(sequenceID: number) {
  const steps = await fetchSteps(sequenceID);
  const { fileCollections, fileCollectionFiles } =
    await getFilesAndFileCollections(steps);
  const files = steps.flatMap((s) => s.files).map((sf) => sf.file);
  return { steps, fileCollections, fileCollectionFiles, files };
}

async function fetchSteps(sequenceID: number) {
  // TODO: fetch more if pagination.pageCount > 1
  const { results } = await API.Sequences.fetchSequenceSteps(sequenceID, {
    filters: [],
    pagination: { page: 1, pageSize: 50 },
    sorting: { sortBy: 'position', sortOrder: SORT_ORDER.ASCENDING },
  });
  return results;
}

async function getFilesAndFileCollections(steps: DecoratedSequenceStep[]) {
  let fileCollections: DecoratedFileCollection[] = [];
  let fileCollectionFiles: DecoratedFileCollectionFile[] = [];
  const fileCollectionIDs = getFileCollectionIDs(steps);
  for (const fileCollectionID of fileCollectionIDs) {
    const data = await fetchFileCollectionData(fileCollectionID);
    fileCollections.push(...data.fileCollections);
    fileCollectionFiles.push(...data.files);
  }
  return { fileCollections, fileCollectionFiles };
}

function getFileCollectionIDs(steps: DecoratedSequenceStep[]) {
  return steps
    .flatMap((s) => s.fileCollections)
    .map((fc) => fc.fileCollectionID);
}

export function createFileCollectionFile(
  fileCollectionID: number,
  fileID: number
) {
  return API.FileCollections.addFileCollectionFile(fileCollectionID, fileID);
}

export function addFileToStep(stepID: number, fileID: number) {
  return API.Sequences.addFileToSequenceStep(stepID, fileID);
}

export function addCollectionToStep(stepID: number, fileCollectionID: number) {
  return API.Sequences.addFileCollectionToSequenceStep(
    stepID,
    fileCollectionID
  );
}

export function getOrderedIDs(steps: UndecoratedSequenceStep[]) {
  return mapToKey(sortByKey(steps, 'position', -1), 'id');
}
