import { type MouseEventHandler } from 'react';
import { type NodeModel } from '@minoru/react-dnd-treeview';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import FileIcon from '@/icons/FileIcon';
import FolderIcon from '@/icons/FolderIcon';
import CheckBox from '@/components/CheckBox';
import TruncatedText from '@/components/TruncatedText';
import { classes, styles } from './utils';

export type TreeItemProps = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  isSelected: boolean | undefined;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (id: NodeModel['id']) => void;
};

export default function TreeItem({
  node,
  depth,
  isOpen,
  isSelected,
  onToggle,
  onSelect,
}: TreeItemProps) {
  const { droppable } = node;

  const handleToggle: MouseEventHandler = (e) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => onSelect(node.id);

  return (
    <div className={classes.wrapper}>
      <CheckBox
        isChecked={isSelected}
        className={classes.checkbox}
        name="checkbox"
        onChange={handleSelect}
      />
      <div className={classes.content} style={styles.content(depth)}>
        {droppable && (
          <div
            className={classes.expandWrapper}
            style={isOpen ? styles.expanded : styles.collapsed}
            onClick={handleToggle}>
            <ArrowRightIcon className={classes.arrowIcon} />
          </div>
        )}
        {droppable ? (
          <FolderIcon className={classes.typeIcon} />
        ) : (
          <FileIcon className={classes.typeIcon} />
        )}
        <TruncatedText className={classes.label}>{node.text}</TruncatedText>
      </div>
    </div>
  );
}
