import { Tree } from '@minoru/react-dnd-treeview';
import TreeItem from './TreeItem';
import { useTreeByStepID } from '@/store/useStore';
import { buildTreeItems, classes } from './utils';

export type FileTreeProps = {
  className?: string;
  stepID: number;
  openFileKeys: string[];
  onExpand?: (fileCollectionID: number) => void;
  onOpenFile: (fileKey: string) => void;
  onOpenCollection: (fileKeys: string[]) => void;
  onEditFile: (fileKey: string) => void;
  onEditCollection: (fileCollectionID: number) => void;
  onDeleteFile: (fileKey: string) => void;
  onDeleteCollection: (fileCollectionID: number) => void;
};

export default function FileTree({
  className,
  stepID,
  openFileKeys,
  onExpand,
  onOpenFile,
  onEditFile,
  onEditCollection,
  onDeleteFile,
  onDeleteCollection,
}: FileTreeProps) {
  const tree = useTreeByStepID(stepID);
  const treeItems = buildTreeItems(tree);

  const handleToggle = (id: string | number, onToggle: () => void) => {
    // NOTE: id won't be a number, but can't modify the NodeModel type
    // TODO: Get the fileCollectionID from the id string
    if (onExpand) onExpand(id as number);
    onToggle();
  };

  const handleSelect = (id: number | string, isCollection: boolean) => {
    if (isCollection) {
      // onOpenCollection(id as number); // TODO: Get an array of all the fileIDs from `tree`
    } else {
      onOpenFile(id as string);
    }
  };

  const handleEdit = (id: number | string, isCollection: boolean) => {
    if (isCollection) onEditCollection(id as number);
    else onEditFile(id as string);
  };

  const handleDelete = (id: number | string, isCollection: boolean) => {
    if (isCollection) onDeleteCollection(id as number);
    else onDeleteFile(id as string);
  };

  const isSelected = (id: number | string, isCollection: boolean) => {
    // NOTE: id won't be a number, but can't modify the NodeModel type
    if (!isCollection) return openFileKeys.includes(id as string);
    /*
    const { isAllOpen, isSomeOpen } = checkOpenFileCollection(
      files,
      openFileIDs,
      id as number
    );
    if (isAllOpen) return true;
    if (isSomeOpen) return undefined;
    else return false;
     */
    return false;
  };

  return treeItems.length === 0 ? (
    <div className={classes.message}>No files found.</div>
  ) : (
    <Tree
      classes={{ root: className }}
      tree={treeItems}
      rootId="root"
      canDrag={() => false}
      canDrop={() => false}
      render={(node, { depth, isOpen, onToggle }) => (
        <TreeItem
          node={node}
          depth={depth}
          isOpen={isOpen}
          isSelected={isSelected(node.id, !!node.droppable)}
          onToggle={() => handleToggle(node.id, onToggle)}
          onSelect={() => handleSelect(node.id, !!node.droppable)}
          onEdit={() => handleEdit(node.id, !!node.droppable)}
          onDelete={() => handleDelete(node.id, !!node.droppable)}
        />
      )}
      onDrop={(source, destination) => {
        console.log(source, destination);
      }}
    />
  );
}
