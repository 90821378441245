import { Tree } from '@minoru/react-dnd-treeview';
import {
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
} from '@witmetrics/api-client';
import TreeItem from './TreeItem';
import { checkOpenFileCollection } from '@/utils/files';

export type FileTreeProps = {
  className?: string;
  parentFileCollectionID: number;
  openFileIDs: number[];
  fileCollections: DecoratedFileCollection[];
  files: DecoratedFileCollectionFile[];
  onExpand?: (fileCollectionID: number) => void;
  onOpenFile: (fileID: number) => void;
  onOpenCollection: (fileCollectionID: number) => void;
};

export default function FileTree({
  className,
  parentFileCollectionID,
  openFileIDs,
  files,
  fileCollections,
  onExpand,
  onOpenFile,
  onOpenCollection,
}: FileTreeProps) {
  // TODO: Make sure there's no overlap between fileCollectionIDs and fileIDs
  const data = [
    ...fileCollections.map((fc) => ({
      id: fc.id,
      parent: fc.parentFileCollectionID!,
      droppable: true,
      text: fc.name,
    })),
    ...files.map((f) => ({
      id: f.fileID,
      parent: f.fileCollectionID,
      droppable: false,
      text: f.file.name,
    })),
  ];

  const handleToggle = (id: number | string, onToggle: () => void) => {
    // NOTE: id won't be a string, but can't modify the NodeModel type
    if (onExpand) onExpand(id as number);
    onToggle();
  };

  const handleSelect = (
    id: number | string,
    isCollection: boolean,
    onToggle: () => void
  ) => {
    if (isCollection) {
      onOpenCollection(id as number);
    } else {
      onOpenFile(id as number);
    }
  };

  const isSelected = (id: number | string, isCollection: boolean) => {
    if (!isCollection) return openFileIDs.includes(id as number);
    const { isAllOpen, isSomeOpen } = checkOpenFileCollection(
      files,
      openFileIDs,
      id as number
    );
    if (isAllOpen) return true;
    if (isSomeOpen) return undefined;
    else return false;
  };

  return (
    <Tree
      classes={{ root: className }}
      tree={data}
      rootId={parentFileCollectionID}
      canDrag={() => false}
      canDrop={() => false}
      render={(node, { depth, isOpen, onToggle }) => (
        <TreeItem
          node={node}
          depth={depth}
          isOpen={isOpen}
          isSelected={isSelected(node.id, !!node.droppable)}
          onToggle={() => handleToggle(node.id, onToggle)}
          onSelect={() => handleSelect(node.id, !!node.droppable, onToggle)}
        />
      )}
      onDrop={(source, destination) => {
        console.log(source, destination);
      }}
    />
  );
}
