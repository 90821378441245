import { GroupedFiles } from '../utils';

export const classes = {
  stepBlock: 'mb-2',
  stepTitle: 'flex items-center justify-between mb-2',
  titleWrapper: 'flex items-center justify-center',
  iconButton: 'text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  closeIcon: 'mr-4',
  title: 'mr-4',
};

export function sortByPosition(groupedFiles: GroupedFiles) {
  return Object.keys(groupedFiles).sort((a, b) => parseInt(a) - parseInt(b));
}
