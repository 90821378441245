export const classes = {
  wrapper:
    'flex items-center justify-between py-3 px-4 w-full hover:bg-grey-2 rounded-lg',
  checkbox: 'mr-3 text-grey-7',
  content: 'flex items-center justify-start grow min-w-0',
  expandWrapper:
    'flex items-center justify-center cursor-pointer transition-all',
  arrowIcon: 'text-grey-7',
  typeIcon: 'ml-1 mr-2 text-grey-6',
  label: 'text-grey-8 text-base truncate',
};

export const styles = {
  content: (indent: number) => ({
    paddingLeft: `${indent * 1.5}rem`,
  }),
  expanded: {
    transform: 'rotate(90deg)',
  },
  collapsed: {
    transform: 'rotate(0deg)',
  },
};
