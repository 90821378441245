import { useEffect, useState } from 'react';
import type { UndecoratedFile } from '@witmetrics/api-client';
import { API } from '@/api';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { useDispatch, useFile } from '@/store/useStore';
import { updateFile as dispatchUpdateFile } from '@/store/slices/filesSlice';
import TextField from '@/components/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { showSuccessToaster } from '@/utils/toasters';
import {
  classes,
  formatExtension,
  isDisabled,
  splitName,
  validateFields,
} from './utils';

type FileInfoDialogProps = {
  fileID: number;
  onSave?: (file: UndecoratedFile) => void;
  onClose: () => void;
};

export default function FileInfoDialog({
  fileID,
  onSave,
  onClose,
}: FileInfoDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [name, setName] = useState('');
  const [extension, setExtension] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const file = useFile(fileID);

  useEffect(() => {
    if (file) fillFields();
  }, [file]);

  const fillFields = () => {
    const parts = splitName(file!.name);
    setName(parts.name);
    setExtension(parts.extension);
    setDescription(file!.description || '');
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalid = validateFields(name);
      if (invalidFields.length > 0) return setInvalidFields(invalid);
      const filename = extension.length > 0 ? `${name}.${extension}` : name;
      const data = { name: filename, description };
      const updatedFile = await API.Files.updateFile(fileID!, data);
      dispatch(dispatchUpdateFile(updatedFile));
      showSuccessToaster('File updated');
      toggleSaving(false);
      if (onSave) onSave(updatedFile);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving file');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Update file" onClose={onClose} />
      <DialogBody className={classes.body}>
        <TextField
          error={invalidFields.includes('name')}
          className={classes.input}
          name="name"
          label="Name"
          value={name}
          onChange={({ name }) => setName(name)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formatExtension(extension)}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          className={classes.input}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={({ description }) => setDescription(description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(name)}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
