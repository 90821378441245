import { type UndecoratedSequence } from '@witmetrics/api-client';
import DirectoryBlock from '@/components/DirectoryBlock';
import SequenceIcon from '@/icons/SequenceIcon';

export type SequenceBlockProps = {
  active?: boolean;
  sequence: UndecoratedSequence;
  onSelect: (sequenceID: number) => void;
  onOpen: (sequenceID: number) => void;
};

export default function SequenceBlock({
  active,
  sequence,
  onSelect,
  onOpen,
}: SequenceBlockProps) {
  return (
    <DirectoryBlock
      active={active}
      name={sequence.name}
      description={sequence.description}
      updatedAt={sequence.updatedAt}
      renderIcon={(iconClassName) => <SequenceIcon className={iconClassName} />}
      onSelect={() => onSelect(sequence.id)}
      onOpen={() => onOpen(sequence.id)}
    />
  );
}
