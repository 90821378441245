import { theme } from '@/utils/theme';

export const classes = {
  formControl: 'w-full',
  iconButton:
    'hover:bg-transparent text-grey-7 hover:text-purple-2 transition-colors',
  searchIcon: 'text-grey-7',
  closeButton:
    'hover:bg-transparent text-grey-6 hover:text-grey-7 transition-colors',
};

export const styles = {
  formControl: {
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: `1px solid ${theme.palette['purple-2'].main}`,
    },
    '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
      {
        transition: 'border-color 0.2s',
        borderColor: theme.palette['purple-2'].main,
      },
  },
};
