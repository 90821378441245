import { type KeyboardEvent, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@/icons/SearchIcon';
import CloseIcon from '@/icons/CloseIcon';
import IconButton from '@mui/material/IconButton';
import { useToggle } from '@/hooks/useToggle';
import { classes, styles } from './utils';

export type ExpandableSearchProps = {
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onClose: () => void;
};

export default function ExpandableSearch({
  disabled,
  className,
  placeholder,
  value,
  onChange,
  onClose,
}: ExpandableSearchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, toggleOpen] = useToggle(false);

  const handleToggleSearch = () => {
    if (isOpen) {
      toggleOpen(false);
      onClose();
    } else {
      toggleOpen(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Clear the search
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      inputRef.current?.blur();
      handleToggleSearch();
    }
  };

  if (!isOpen) {
    return (
      <IconButton className={classes.iconButton} onClick={handleToggleSearch}>
        <SearchIcon />
      </IconButton>
    );
  }

  return (
    <FormControl
      disabled={disabled}
      sx={styles.formControl}
      className={twMerge(classes.formControl, className)}
      variant="standard">
      <Input
        inputRef={inputRef}
        autoFocus
        autoComplete="off"
        type="text"
        placeholder={placeholder || 'Search...'}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => onChange(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className={classes.closeButton}
              onClick={handleToggleSearch}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
