import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteFileCollection } from './fileCollectionsSlice';
import { deleteSequence } from './sequencesSlice';
import {
  DecoratedFileCollectionSequence,
  UndecoratedFileCollectionSequence,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type FileCollectionSequencesState = {
  byID: Record<string, UndecoratedFileCollectionSequence>;
  byFileCollectionID: Record<string, string[]>;
  bySequenceID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byFileCollectionID: {},
  bySequenceID: {},
} satisfies FileCollectionSequencesState as FileCollectionSequencesState;

export const fileCollectionSequencesSlice = createSlice({
  name: 'fileCollectionSequences',
  initialState,
  reducers: {
    setFileCollectionSequences: (
      state,
      action: PayloadAction<DecoratedFileCollectionSequence[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addFileCollectionSequence: (
      state,
      action: PayloadAction<DecoratedFileCollectionSequence>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteFileCollectionSequence: (
      state,
      action: PayloadAction<{ fileCollectionID: number; sequenceID: number }>
    ) => {
      const key = buildFileCollectionSequenceKey(
        action.payload.fileCollectionID,
        action.payload.sequenceID
      );
      if (!state.byID[key]) return state;
      const fileCollectionID = action.payload.fileCollectionID;
      if (state.byFileCollectionID[fileCollectionID]) {
        state.byFileCollectionID[fileCollectionID] = state.byFileCollectionID[
          fileCollectionID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byFileCollectionID: {}, bySequenceID: {} };
      })
      .addCase(deleteFileCollection, (state, action) => {
        const fileCollectionID = action.payload.fileCollectionID;
        const keys = state.byFileCollectionID[fileCollectionID] || [];
        keys.forEach((key) => {
          delete state.byID[key];
        });
        delete state.byFileCollectionID[fileCollectionID];
      })
      .addCase(deleteSequence, (state, action) => {
        const sequenceID = action.payload.sequenceID;
        const keys = state.bySequenceID[sequenceID] || [];
        keys.forEach((key) => {
          delete state.byID[key];
        });
        delete state.bySequenceID[sequenceID];
      })
      .addDefaultCase(() => {});
  },
});

function formatFileCollectionSequence({
  sequence,
  ...fileCollectionSequence
}: DecoratedFileCollectionSequence) {
  return fileCollectionSequence;
}

function getNextState(
  state: FileCollectionSequencesState,
  fileCollectionSequences: DecoratedFileCollectionSequence[]
) {
  let byID = { ...state.byID };
  let byFileCollectionID = { ...state.byFileCollectionID };
  let bySequenceID = { ...state.bySequenceID };
  fileCollectionSequences.forEach((fcs) => {
    const key = buildFileCollectionSequenceKey(
      fcs.fileCollectionID,
      fcs.sequenceID
    );
    byID[key] = formatFileCollectionSequence(fcs);
    byFileCollectionID[fcs.fileCollectionID] = addToIDArray(
      byFileCollectionID[fcs.fileCollectionID],
      key
    );
    bySequenceID[fcs.sequenceID] = addToIDArray(
      bySequenceID[fcs.sequenceID],
      key
    );
  });
  return { byID, byFileCollectionID, bySequenceID };
}

export function buildFileCollectionSequenceKey(
  fileCollectionID: number,
  sequenceID: number
) {
  return `${fileCollectionID}.${sequenceID}`;
}

export const {
  setFileCollectionSequences,
  addFileCollectionSequence,
  deleteFileCollectionSequence,
} = fileCollectionSequencesSlice.actions;

export default fileCollectionSequencesSlice.reducer;
