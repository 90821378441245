import { type MouseEventHandler, useRef } from 'react';
import { type NodeModel } from '@minoru/react-dnd-treeview';
import Box from '@mui/material/Box';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import FileIcon from '@/icons/FileIcon';
import FolderIcon from '@/icons/FolderIcon';
import CheckBox from '@/components/CheckBox';
import TruncatedText from '@/components/TruncatedText';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import OptionsIcon from '@/icons/OptionsIcon';
import { useToggle } from '@/hooks/useToggle';
import { classes, styles } from './utils';

export type TreeItemProps = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  isSelected: boolean | undefined;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (id: NodeModel['id']) => void;
  onEdit: (id: NodeModel['id']) => void;
  onDelete: (id: NodeModel['id']) => void;
};

export default function TreeItem({
  node,
  depth,
  isOpen,
  isSelected,
  onToggle,
  onSelect,
  onEdit,
  onDelete,
}: TreeItemProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const { droppable } = node;

  const handleToggle: MouseEventHandler = (e) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => onSelect(node.id);

  const handleEdit = () => {
    onEdit(node.id);
    toggleMenu(false);
  };

  const handleDelete = () => {
    onDelete(node.id);
    toggleMenu(false);
  };

  return (
    <div className={classes.wrapper}>
      <CheckBox
        isChecked={isSelected}
        className={classes.checkbox}
        name="checkbox"
        onChange={handleSelect}
      />
      <Box className={classes.itemWrapper} sx={styles.itemWrapper}>
        <div className={classes.content} style={styles.content(depth)}>
          {droppable && (
            <div
              className={classes.expandWrapper}
              style={isOpen ? styles.expanded : styles.collapsed}
              onClick={handleToggle}>
              <ArrowRightIcon className={classes.arrowIcon} />
            </div>
          )}
          {droppable ? (
            <FolderIcon className={classes.typeIcon} />
          ) : (
            <FileIcon className={classes.typeIcon} />
          )}
          <TruncatedText className={classes.label}>{node.text}</TruncatedText>
        </div>
        <OptionsIcon
          ref={menuToggleRef}
          className={classes.optionsIcon}
          onClick={() => toggleMenu()}
        />
        {isMenuOpen && (
          <DefaultOptionsMenu
            open={isMenuOpen}
            anchorTo={menuToggleRef.current}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onClose={() => toggleMenu(false)}
          />
        )}
      </Box>
    </div>
  );
}
