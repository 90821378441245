import Menu, { MenuItem, type MenuProps } from '@/components/Menu';
import { FILE_TYPES, type FileType } from '@/constants/fileTypes';
import { classes } from './utils';

export interface FileTypeDropdownProps extends MenuProps {
  onChange: (fileType: FileType) => void;
}

export default function FileTypeDropdown({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onChange,
  onClose,
}: FileTypeDropdownProps) {
  const handleChange = (fileType: FileType) => {
    onChange(fileType);
    onClose();
  };

  return (
    <Menu
      open={open}
      className={classes.menu}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <ul>
        {Object.values(FILE_TYPES).map(({ name, label }) => (
          <MenuItem
            key={name}
            label={label}
            onClick={() => handleChange(name)}
          />
        ))}
      </ul>
    </Menu>
  );
}
