import { twMerge } from 'tailwind-merge';
import Tooltip from '@/components/Tooltip';
import InfoIcon from '@/icons/InfoIcon';
import { classes } from './utils';

export type InfoProps = {
  description: string;
  className?: string;
};

export default function Info({ description, className = '' }: InfoProps) {
  return (
    <Tooltip className={classes.tooltip} title={description}>
      <InfoIcon className={twMerge(classes.icon, className)} />
    </Tooltip>
  );
}
