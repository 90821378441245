// import { useEffect, useRef, useState } from 'react';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { type SlideProps } from '@mui/material/Slide';
import Card from '@/components/Card';
import Spinner from '@/components/Spinner';
// import CheckIcon from '@/icons/CheckIcon';
import { classes } from './utils';

export type UploadToasterProps = {
  open: boolean;
  files: string[];
  onClose: () => void;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export default function UploadToaster({
  open,
  files,
  onClose,
}: UploadToasterProps) {
  // const wasLoading = useRef(isLoading);
  const [duration, setDuration] = useState<number | null>(null);

  // useEffect(() => {
  //   if (!isLoading && wasLoading) {
  //     setDuration(2000);
  //   }
  // }, [isLoading]);

  const handleClose = () => {
    setTimeout(() => onClose(), 2000);
  };

  return (
    <Snackbar
      open={open}
      TransitionComponent={SlideTransition}
      autoHideDuration={duration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={handleClose}>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          {files.map((file) => (
            <div key={file} className={classes.file}>
              <Spinner className={classes.spinner} size={20} />
              <span className={classes.name}>{file}</span>
            </div>
          ))}
        </Card>
      </div>
    </Snackbar>
  );
}
