import { useEffect, useState } from 'react';
import { DecoratedSequenceStep } from '@witmetrics/api-client';
import { useDispatch, useSequenceSteps, useStep } from '@/store/useStore';
import {
  addSequenceStep as dispatchAddSequenceStep,
  updateSequenceStep as dispatchUpdateSequenceStep,
} from '@/store/slices/sequenceStepsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { showErrorToaster, showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, createStep, updateStep } from './utils';

export type SequenceStepDialogProps = {
  sequenceID?: number;
  stepID?: number;
  onSave?: (step: DecoratedSequenceStep) => void;
  onClose: () => void;
};

export default function SequenceStepDialog({
  sequenceID,
  stepID,
  onClose,
  onSave,
}: SequenceStepDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const step = useStep(stepID);
  const sequenceSteps = useSequenceSteps(
    sequenceID || step?.sequenceID || null
  );
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [position, setPosition] = useState(
    step?.position || sequenceSteps?.length || 0
  );
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (step) fillFields();
  }, [step]);

  const fillFields = () => {
    setName(step!.name || '');
    setDescription(step!.description || '');
    setPosition(step!.position);
  };

  const handleCreateStep = async () => {
    const stepData = await createStep(sequenceID!, { name, description });
    dispatch(dispatchAddSequenceStep(stepData));
    return stepData;
  };

  const handleUpdateStep = async () => {
    const stepData = await updateStep(stepID!, { name, description, position });
    dispatch(dispatchUpdateSequenceStep(stepData));
    showSuccessToaster('Step updated');
    return stepData;
  };

  const handleSave = async () => {
    const errorMessage = 'Error saving step';
    try {
      toggleSaving(true);
      if (!stepID && !sequenceID) {
        showErrorToaster(errorMessage);
        return toggleSaving(false);
      }
      const stepData = stepID
        ? await handleUpdateStep()
        : await handleCreateStep();
      if (onSave) onSave(stepData);
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, errorMessage, () => handleSave());
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title={stepID ? 'Edit Step' : 'New Step'}
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <div className={classes.nameWrapper}>
          <TextField
            autoFocus
            className={classes.name}
            name="name"
            label="Name (optional)"
            value={name}
            onChange={(update) => setName(update.name)}
          />
          {/* TODO {step && (*/}
          {/*  <SelectDropdown<number, 'position'>*/}
          {/*    className={classes.position}*/}
          {/*    name="position"*/}
          {/*    label="Step #"*/}
          {/*    value={buildOption(position, position + 1)}*/}
          {/*    options={buildStepOptions(sequenceSteps, false)}*/}
          {/*    onChange={(update) => setPosition(update.position.value)}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
        <TextField
          multiline
          className={classes.description}
          minRows={3}
          maxRows={5}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={(update) => setDescription(update.description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaving}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
