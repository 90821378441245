import { dialogClasses } from '@mui/material/Dialog';

export function isInput(e: MouseEvent | KeyboardEvent) {
  return (
    e.target instanceof HTMLInputElement ||
    e.target instanceof HTMLTextAreaElement
  );
}

export function isDialogsOpen() {
  return document.querySelectorAll(`.${dialogClasses.root}`).length > 0;
}
