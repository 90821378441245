import { useEffect } from 'react';
import Scene from '@/components/Scene';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { useScene } from '@/providers/SceneProvider';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { centerObjects } from '@/utils/scans';
import ImageViewer from '@/components/ImageViewer';
import PdfViewer from '@/components/PdfViewer';
import VideoViewer from '@/components/VideoViewer';
import { useFile } from '@/store/useStore';
import {
  getLoadedImage,
  getLoadedPdf,
  getLoadedScan,
  getLoadedVideo,
} from '@/utils/files';
import { classes } from './utils';

export type ViewerContentProps = {
  isLoading: boolean;
  fileID: number;
};

export default function ViewerContent({
  isLoading,
  fileID,
}: ViewerContentProps) {
  const { loadedFiles } = useLoadedFiles();
  const { addToScene, clearScene } = useScene();
  const file = useFile(fileID);

  useEffect(() => {
    handleUpdateScene();
  }, [loadedFiles]);

  const handleUpdateScene = () => {
    const loadedScan = getLoadedScan(file, loadedFiles);
    clearScene();
    if (!loadedScan) return;
    addToScene([centerObjects([loadedScan])]);
  };

  const renderMessage = () => {
    return (
      isLoading && (
        <div className={classes.message}>
          Loading file
          <LoopingEllipsis />
        </div>
      )
    );
  };

  const renderViewer = () => {
    if (!file) return null;
    const loadedScan = getLoadedScan(file, loadedFiles);
    if (loadedScan) {
      return <Scene className={classes.scene} />;
    }
    const loadedImage = getLoadedImage(file, loadedFiles);
    if (loadedImage) {
      return <ImageViewer src={loadedImage} />;
    }
    const loadedPdf = getLoadedPdf(file, loadedFiles);
    if (loadedPdf) {
      return <PdfViewer className={classes.pdfViewer} src={loadedPdf} />;
    }
    const loadedVideo = getLoadedVideo(file, loadedFiles);
    if (loadedVideo) {
      return <VideoViewer className={classes.videoViewer} src={loadedVideo} />;
    }
    // TODO: Show an "Unable to display file" message, give option to download
    return null;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.sceneWrapper}>
        {renderMessage()}
        {renderViewer()}
      </div>
    </div>
  );
}
