import { theme } from '@/utils/theme';

export const classes = {
  wrapper: 'fixed h-16 opacity-100 transition-all duration-200 mb-6',
  content:
    'flex items-center justify-between opacity-100 h-full w-full bg-white rounded-lg pl-4 pr-0 shadow-dialog border border-grey-4',
  hiddenWrapper: 'opacity-0 h-8',
  hiddenContent: 'opacity-0 h-0',
  labelWrapper: 'flex items-center justify-start',
  typeIcon: 'text-grey-5 mr-3',
  label:
    'text-grey-8 text-base overflow-hidden whitespace-nowrap overflow-ellipsis',
  optionsWrapper: 'flex items-center',
  icon: 'text-2xl text-grey-7',
  delete: 'text-red-2',
};

export const styles = {
  wrapper: {
    width: '90vw',
    left: '5vw',
    bottom: '0',
    zIndex: theme.zIndex.drawer, // Match z-index of ProjectOverlay
  },
};
