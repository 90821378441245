import { API } from '@/api';

export const classes = {
  dialog: 'w-[98vw] max-w-[800px] min-w-[300px]',
  body: 'w-full pt-2 pb-12 overflow-auto text-grey-7',
  section: 'p-4 my-4 border border-grey-3 rounded-lg',
  sharingSection: 'flex',
  toggle: 'shrink-0 mr-4',
  linkSection: 'flex items-center',
  linkInput: 'grow mr-2',
  message: 'text-grey-8 font-medium',
};

export function fetchSharedContent(fileID: number) {
  return API.SharedContent.fetchSharedContentByFileID(fileID);
}

export function createSharedContent(fileID: number) {
  // Default to unlocked
  return API.SharedContent.createSharedContent({ fileID, isLocked: false });
}

export function updateSharedContent(sharedContentID: number, enabled: boolean) {
  return API.SharedContent.updateSharedContent(sharedContentID, { enabled });
}
