import { useEffect, useState } from 'react';
import {
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
} from '@witmetrics/api-client';
import Dialog from '@/components/Dialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  downloadFileVersion,
  fetchFileCollectionData,
  fetchFileVersion,
  isScan,
  isViewableNonScan,
} from '@/utils/files';
import { useFileCollection } from '@/store/useStore';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { mergeWithoutDuplicatesByKey } from '@/utils/arrays';
import { showErrorToaster } from '@/utils/toasters';
import { loadScan } from '@/utils/scans';
import ViewerHeader from '@/components/ViewerHeader';
import ViewerContent from './ViewerContent';
import FolderIcon from '@/icons/FolderIcon';
import SceneProvider from '@/providers/SceneProvider';
import FileCollectionDialog from '@/dialogs/FileCollectionDialog';
import DeleteFileCollectionDialog from '@/dialogs/DeleteFileCollectionDialog';
import { classes } from './utils';

export type FileCollectionViewerDialogProps = {
  fileCollectionID: number;
  onDelete?: () => void;
  onClose: () => void;
};

export default function FileCollectionViewerDialog({
  fileCollectionID,
  onDelete,
  onClose,
}: FileCollectionViewerDialogProps) {
  const { onApiError } = useAppState();
  const { loadedFiles, addLoadedFile } = useLoadedFiles();
  const [fileCollections, setFileCollections] = useState<
    DecoratedFileCollection[]
  >([]);
  const [files, setFiles] = useState<DecoratedFileCollectionFile[]>([]);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);
  const fileCollection = useFileCollection(fileCollectionID);

  useEffect(() => {
    fetchData();
  }, [fileCollectionID]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchFileCollectionData(fileCollectionID);
      setFileCollections(
        mergeWithoutDuplicatesByKey(fileCollections, data.fileCollections, 'id')
      );
      setFiles(mergeWithoutDuplicatesByKey(files, data.files, 'fileID'));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file collection');
    }
  };

  const handleOpenFiles = async (fileIDs: number[]) => {
    try {
      const allLoaded = fileIDs.every((id) => loadedFiles[id]);
      if (allLoaded) return;
      toggleFetchingData(true);
      for (const fileID of fileIDs) {
        if (loadedFiles[fileID]) continue; // It's already been loaded
        const fileVersion = await fetchFileVersion(fileID);
        if (!fileVersion) {
          showErrorToaster('File version not found');
          continue;
        }
        const arrayBuffer = await downloadFileVersion(fileVersion.id);
        if (isScan(fileVersion.name)) {
          const mesh = loadScan(fileVersion.name, arrayBuffer);
          if (!mesh) {
            showErrorToaster('Unrecognized scan format');
            continue;
          }
          addLoadedFile(fileID, mesh);
        } else if (isViewableNonScan(fileVersion.name)) {
          addLoadedFile(fileID, URL.createObjectURL(new Blob([arrayBuffer])));
        }
      }
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error loading file(s)');
    }
  };

  const handleEdit = () => toggleEditing(true);

  const handleCloseEditDialog = () => toggleEditing(false);

  const handleStartDelete = () => toggleDeleteDialog(true);

  const handleCloseDeleteDialog = () => toggleDeleteDialog(false);

  const handleDeleteFileCollection = () => {
    handleCloseDeleteDialog();
    if (onDelete) onDelete();
    onClose();
  };

  return (
    <>
      <Dialog className={classes.dialog} onClose={onClose}>
        {fileCollection && (
          <>
            <ViewerHeader
              label={fileCollection.name}
              renderIcon={(iconClassName) => (
                <FolderIcon className={iconClassName} />
              )}
              onEdit={handleEdit}
              onDelete={handleStartDelete}
              onClose={onClose}
            />
            <SceneProvider backgroundColor={0xf3f5f7 /*bg-grey-2*/}>
              <ViewerContent
                isLoading={isFetchingData}
                fileCollectionID={fileCollectionID}
                fileCollections={fileCollections}
                files={files}
                onOpenFiles={handleOpenFiles}
              />
            </SceneProvider>
          </>
        )}
      </Dialog>
      {isEditing && (
        <FileCollectionDialog
          fileCollectionID={fileCollectionID}
          onClose={handleCloseEditDialog}
        />
      )}
      {isDeleteDialogShown && (
        <DeleteFileCollectionDialog
          fileCollectionID={fileCollectionID}
          onDelete={handleDeleteFileCollection}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </>
  );
}
