import { API } from '@/api';
import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  input: 'w-full my-4',
};

export function validateFields(name: string) {
  let fields: string[] = [];
  if (isBlank(name)) fields.push('name');
  return fields;
}

export function isDisabled(name: string) {
  return isBlank(name);
}

export function updateSequence(
  sequenceID: number,
  name: string,
  description: string
) {
  return API.Sequences.updateSequence(sequenceID, {
    name,
    description,
  });
}
