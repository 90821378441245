import type { UndecoratedFile } from '@witmetrics/api-client';
import { TREE_ITEM_TYPES } from '@/constants/treeItemTypes';
import { formatTreeID } from '@/utils/files';

export type ParsedFile = {
  id: number;
  fileKey: string;
};

export const classes = {
  sidebar: 'border-r border-grey-4 shrink-0 w-[300px]',
};

export function parseFiles(files: UndecoratedFile[]): ParsedFile[] {
  return files.map((f) => ({
    id: f.id,
    fileKey: formatTreeID(f.id, TREE_ITEM_TYPES.FILE),
  }));
}
