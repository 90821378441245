import type { PropsWithChildren } from 'react';
import Tooltip from '@/components/Tooltip';
import { classes } from './utils';

export interface OptionButtonProps extends PropsWithChildren {
  title?: string;
  onClick: () => void;
}

export default function OptionButton({
  title,
  children,
  onClick,
}: OptionButtonProps) {
  const button = (
    <button className={classes.iconButton} onClick={onClick}>
      {children}
    </button>
  );
  return title ? <Tooltip title={title}>{button}</Tooltip> : button;
}
