export const classes = {
  listItem:
    'flex justify-between items-center rounded-xl bg-grey-2 my-2 px-4 py-3',
  options: 'flex',
  nameWrapper: 'flex item-center justify-start',
  leftIcon: 'mr-2',
  dragIcon:
    'js-drag-handle text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  checkIcon: 'text-green-2',
  errorIcon: 'text-red-2',
  fileTypeSelector: 'mr-4',
  name: 'text-base font-medium text-grey-8',
  closeIcon: 'text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  disabled: 'cursor-not-allowed hover:text-grey-6',
};
