'use client';
import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Portal from '@mui/material/Portal';
import Divider from '@/components/Divider';
import DownloadIcon from '@/icons/DownloadIcon';
import ShareIcon from '@/icons/ShareIcon';
// import MoveFileIcon from '@/icons/MoveFileIcon';
import ShowIcon from '@/icons/ShowIcon';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import CloseIcon from '@/icons/CloseIcon';
import OptionButton from './OptionButton';
import { classes, styles } from './utils';

export type FileCollectionDetailsBarProps = {
  open?: boolean;
  label: string;
  renderIcon: (className: string) => ReactNode;
  onDownload: () => void;
  onShare: () => void;
  onView: () => void;
  onMoveTo: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export default function FileCollectionDetailsBar({
  open,
  label,
  renderIcon,
  onDownload,
  onShare,
  onView,
  onMoveTo,
  onEdit,
  onDelete,
  onClose,
}: FileCollectionDetailsBarProps) {
  return (
    <Portal>
      <div
        style={styles.wrapper}
        className={twMerge(classes.wrapper, !open && classes.hiddenWrapper)}>
        <div
          className={twMerge(classes.content, !open && classes.hiddenContent)}>
          {open && (
            <>
              <div className={classes.labelWrapper}>
                {renderIcon(classes.typeIcon)}
                <div className={classes.label}>{label}</div>
              </div>
              <div className={classes.optionsWrapper}>
                <OptionButton title="Download" onClick={onDownload}>
                  <DownloadIcon className={classes.icon} />
                </OptionButton>
                <OptionButton title="Share" onClick={onShare}>
                  <ShareIcon className={classes.icon} />
                </OptionButton>
                {/*<OptionButton title="Move to" onClick={onMoveTo}>*/}
                {/*  <MoveFileIcon className={classes.icon} />*/}
                {/*</OptionButton>*/}
                <OptionButton title="View" onClick={onView}>
                  <ShowIcon className={classes.icon} />
                </OptionButton>
                <OptionButton title="Edit" onClick={onEdit}>
                  <EditIcon className={classes.icon} />
                </OptionButton>
                <OptionButton title="Delete" onClick={onDelete}>
                  <DeleteIcon
                    className={twMerge(classes.icon, classes.delete)}
                  />
                </OptionButton>
                <Divider flexItem orientation="vertical" variant="middle" />
                <OptionButton onClick={onClose}>
                  <CloseIcon className={classes.icon} />
                </OptionButton>
              </div>
            </>
          )}
        </div>
      </div>
    </Portal>
  );
}
