import { useEffect } from 'react';
import Dialog from '@/components/Dialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { showErrorToaster } from '@/utils/toasters';
import {
  downloadFileVersion,
  fetchFileVersion,
  isScan,
  isViewableNonScan,
  openFileDownloadLink,
} from '@/utils/files';
import { loadScan } from '@/utils/scans';
import {
  useDispatch,
  useFile,
  useFileVersionFromFileID,
} from '@/store/useStore';
import { addFileVersion as dispatchAddFileVersion } from '@/store/slices/fileVersionsSlice';
import SceneProvider from '@/providers/SceneProvider';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import ViewerHeader from '@/components/ViewerHeader';
import FileIcon from '@/icons/FileIcon';
import FileInfoDialog from '@/dialogs/FileInfoDialog';
import DeleteFileDialog from '@/dialogs/DeleteFileDialog';
import DownloadToaster from '@/components/DownloadToaster';
import ViewerContent from './ViewerContent';
import ShareFileDialog from '@/dialogs/ShareFileDialog';
import { classes } from './utils';

export type FileViewerDialogProps = {
  fileID: number;
  onDelete?: (fileID: number) => void;
  onClose: () => void;
};

export default function FileViewerDialog({
  fileID,
  onDelete,
  onClose,
}: FileViewerDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const file = useFile(fileID);
  const fileVersion = useFileVersionFromFileID(fileID);
  const { loadedFiles, addLoadedFile } = useLoadedFiles();
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isDownloadToasterOpen, toggleDownloadToaster] = useToggle(false);
  const [isPreparingDownload, togglePreparingDownload] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSharing, toggleSharing] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);

  useEffect(() => {
    if (!fileVersion) {
      fetchData();
    } else if (fileVersion && !loadedFiles[fileID]) {
      handleLoadFileVersion();
    } else {
      toggleFetchingData(false);
    }
  }, [fileID, fileVersion]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const fileVersion = await fetchFileVersion(fileID);
      if (!fileVersion) showErrorToaster('File version not found');
      else dispatch(dispatchAddFileVersion(fileVersion));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file version');
    }
  };

  const handleLoadFileVersion = async () => {
    try {
      toggleFetchingData(true);
      const blob = await downloadFileVersion(fileVersion!.id);
      if (isScan(fileVersion!.name)) {
        const mesh = loadScan(fileVersion!.name, blob);
        if (!mesh) return showErrorToaster('Unrecognized scan format');
        addLoadedFile(fileID, mesh);
      } else if (isViewableNonScan(fileVersion!.name)) {
        addLoadedFile(fileID, URL.createObjectURL(new Blob([blob])));
      }
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error loading file');
    }
  };

  const handleFileDownload = async () => {
    try {
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      if (!fileVersion) return showErrorToaster('File version not found');
      await openFileDownloadLink(fileVersion.id, file.name);
      togglePreparingDownload(false);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading file');
    }
  };

  const handleCloseDownloadToaster = () => {
    togglePreparingDownload(false);
    toggleDownloadToaster(false);
  };

  const handleEdit = () => toggleEditing(true);

  const handleShare = () => toggleSharing(true);

  const handleStartDelete = () => toggleDeleteDialog(true);

  const handleCloseDeleteDialog = () => toggleDeleteDialog(false);

  const handleDelete = () => {
    handleCloseDeleteDialog();
    if (onDelete) onDelete(fileID);
    onClose();
  };

  return (
    <>
      <Dialog className={classes.dialog} onClose={onClose}>
        {file && (
          <>
            <ViewerHeader
              label={file.name}
              renderIcon={(iconClassName) => (
                <FileIcon className={iconClassName} />
              )}
              onDownload={handleFileDownload}
              onEdit={handleEdit}
              onShare={handleShare}
              onDelete={handleStartDelete}
              onClose={onClose}
            />
            <SceneProvider backgroundColor={0xf3f5f7 /*bg-grey-2*/}>
              <ViewerContent isLoading={isFetchingData} fileID={fileID} />
            </SceneProvider>
          </>
        )}
      </Dialog>
      {isEditing && <FileInfoDialog fileID={fileID} onClose={toggleEditing} />}
      {isSharing && <ShareFileDialog fileID={fileID} onClose={toggleSharing} />}
      {isDeleteDialogShown && (
        <DeleteFileDialog
          fileID={fileID}
          onDelete={handleDelete}
          onClose={handleCloseDeleteDialog}
        />
      )}
      <DownloadToaster
        open={isDownloadToasterOpen}
        isLoading={isPreparingDownload}
        onClose={handleCloseDownloadToaster}
      />
    </>
  );
}
