import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import Scene from '@/components/Scene';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { useScene } from '@/providers/SceneProvider';
import { useFilesFromIDArray } from '@/store/useStore';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { centerObjects } from '@/utils/scans';
import ImageViewer from '@/components/ImageViewer';
import TabsBlock, { Tab, type TabsBlockProps } from '@/components/TabsBlock';
import VideoViewer from '@/components/VideoViewer';
import PdfViewer from '@/components/PdfViewer';
import {
  classes,
  getLoadedImages,
  getLoadedPdfs,
  getLoadedScans,
  getLoadedVideos,
  hasMedia,
  hasOnlyImage,
  hasOnlyPdf,
  hasOnlyVideo,
  LoadedMedia,
  parseFileKeys,
} from './utils';

export type ViewerContentProps = {
  isLoading: boolean;
  openFileKeys: string[];
};

export default function ViewerContent({
  isLoading,
  openFileKeys,
}: ViewerContentProps) {
  const { loadedFiles } = useLoadedFiles();
  const { addToScene, clearScene } = useScene();
  const files = useFilesFromIDArray(parseFileKeys(openFileKeys));
  const openFileIDs = parseFileKeys(openFileKeys);

  useEffect(() => {
    handleUpdateScene();
  }, [openFileKeys, loadedFiles]);

  const handleUpdateScene = () => {
    const loadedScans = getLoadedScans(openFileIDs, loadedFiles);
    if (loadedScans.length === 0) clearScene();
    else addToScene([centerObjects(loadedScans)], true);
  };

  const renderMessage = () => {
    if (isLoading) {
      return (
        <div className={classes.message}>
          Loading file(s)
          <LoopingEllipsis />
        </div>
      );
    } else if (openFileIDs.length === 0) {
      return <div className={classes.message}>Select File(s)</div>;
    } else return null;
  };

  const renderTabsBlock = (tabs: TabsBlockProps<any>['children']) => {
    return (
      <TabsBlock
        className={classes.tabsBlock}
        bodyClassName={classes.tabsContent}
        name="Viewer">
        {tabs}
      </TabsBlock>
    );
  };

  const buildMediaTabs = (
    loadedImages: LoadedMedia[],
    loadedVideos: LoadedMedia[],
    loadedPdfs: LoadedMedia[]
  ) => {
    return [
      ...loadedImages.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <ImageViewer key={id} src={src} />
        </Tab>
      )),
      ...loadedVideos.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <VideoViewer key={id} src={src} />
        </Tab>
      )),
      ...loadedPdfs.map(({ id, name, src }) => (
        <Tab key={id} className={classes.tabsContent} name={name} label={name}>
          <PdfViewer key={id} src={src} />
        </Tab>
      )),
    ];
  };

  const renderViewer = () => {
    if (isLoading) return null;
    const loadedScans = getLoadedScans(openFileIDs, loadedFiles);
    const loadedImages = getLoadedImages(openFileIDs, loadedFiles, files);
    const loadedPdfs = getLoadedPdfs(openFileIDs, loadedFiles, files);
    const loadedVideos = getLoadedVideos(openFileIDs, loadedFiles, files);
    const hasMediaContent = hasMedia(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyImage = hasOnlyImage(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyPdf = hasOnlyPdf(loadedImages, loadedPdfs, loadedVideos);
    const isOnlyVideo = hasOnlyVideo(loadedImages, loadedPdfs, loadedVideos);
    if (loadedScans.length > 0 && hasMediaContent) {
      return renderTabsBlock([
        <Tab
          key="scans"
          className={classes.tabsContent}
          name="SCANS"
          label="Scans">
          <Scene className={classes.scene} />
        </Tab>,
        ...buildMediaTabs(loadedImages, loadedVideos, loadedPdfs),
      ]);
    } else if (loadedScans.length > 0 || openFileKeys.length === 0) {
      return (
        <Scene
          className={twMerge(
            classes.scene,
            openFileKeys.length === 0 && classes.hiddenScene
          )}
        />
      );
    } else if (isOnlyImage) {
      return (
        <div className={classes.media}>
          <ImageViewer src={loadedImages[0].src} />
        </div>
      );
    } else if (isOnlyPdf) {
      return (
        <div className={classes.media}>
          <PdfViewer src={loadedPdfs[0].src} />
        </div>
      );
    } else if (isOnlyVideo) {
      return (
        <div className={classes.media}>
          <VideoViewer src={loadedVideos[0].src} />
        </div>
      );
    } else if (hasMediaContent) {
      return renderTabsBlock(
        buildMediaTabs(loadedImages, loadedVideos, loadedPdfs)
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      {renderMessage()}
      {renderViewer()}
    </div>
  );
}
