import { type CSSProperties, useEffect, useState } from 'react';
import Tab from './Tab';
import {
  classes,
  getActiveElementSizing,
  getInitialTab,
  getTabClasses,
  isTabChanged,
  styles,
  type TabChild,
} from './utils';

export type TabsBlockProps<T extends string[]> = {
  name: string;
  children: TabChild[];
  defaultTab?: T[number];
  className?: string;
  style?: CSSProperties;
  bodyClassName?: string;
  bodyStyle?: CSSProperties;
  onChange?: (tabName: T[number]) => void;
};

export default function TabsBlock<T extends string[]>({
  name,
  children,
  defaultTab,
  style = {},
  className = '',
  bodyStyle = {},
  bodyClassName = '',
  onChange,
}: TabsBlockProps<T>) {
  const [activeTab, setActiveTab] = useState(
    getInitialTab(children, defaultTab)
  );
  const [barSize, setBarSize] = useState({ left: '0px', width: '0px' });

  useEffect(() => {
    const sizing = getActiveElementSizing(name);
    if (sizing) setBarSize(sizing);
  }, [activeTab]);

  useEffect(() => {
    // Handle programmatically passing down a new tab
    if (defaultTab && isTabChanged(children, activeTab, defaultTab)) {
      setActiveTab(defaultTab);
    }
  }, [defaultTab]);

  const handleSelectTab = (tabName: T[number]) => {
    setActiveTab(tabName);
    if (onChange) onChange(tabName);
  };

  return (
    <div className={className} style={style}>
      <div className={classes.labelsWrapper}>
        {children.map((tab, i) => (
          <div
            key={tab.props.name}
            style={styles.label}
            className={getTabClasses(tab, i, children, name, activeTab)}
            onClick={() =>
              !tab.props.disabled && handleSelectTab(tab.props.name)
            }>
            {tab.props.label}
          </div>
        ))}
        <div className={classes.bar} style={barSize} />
      </div>
      <div className={bodyClassName} style={bodyStyle}>
        {children.find(({ props: { name } }) => name === activeTab)}
      </div>
    </div>
  );
}

export { Tab };
