import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import {
  addSequenceStep,
  deleteSequenceStep,
  setSequenceSteps,
  updateSequenceStep,
} from './sequenceStepsSlice';
import {
  DecoratedSequenceStepFileCollection,
  UndecoratedSequenceStepFileCollection,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type SequenceStepFileCollectionsState = {
  byID: Record<string, UndecoratedSequenceStepFileCollection>;
  bySequenceStepID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  bySequenceStepID: {},
} satisfies SequenceStepFileCollectionsState as SequenceStepFileCollectionsState;

export const sequenceStepFileCollectionsSlice = createSlice({
  name: 'sequenceStepFileCollections',
  initialState,
  reducers: {
    setSequenceStepFileCollections: (
      state,
      action: PayloadAction<DecoratedSequenceStepFileCollection[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addSequenceStepFileCollection: (
      state,
      action: PayloadAction<DecoratedSequenceStepFileCollection>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteSequenceStepFileCollection: (
      state,
      action: PayloadAction<{
        sequenceStepID: number;
        fileCollectionID: number;
      }>
    ) => {
      const key = buildSequenceStepFileCollectionKey(
        action.payload.sequenceStepID,
        action.payload.fileCollectionID
      );
      if (!state.byID[key]) return state;
      const sequenceStepID = action.payload.sequenceStepID;
      if (state.bySequenceStepID[sequenceStepID]) {
        state.bySequenceStepID[sequenceStepID] = state.bySequenceStepID[
          sequenceStepID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, bySequenceStepID: {} };
      })
      .addCase(setSequenceSteps, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap(({ fileCollections }) => fileCollections)
        );
      })
      .addCase(addSequenceStep, (state, action) => {
        return getNextState(state, action.payload.fileCollections);
      })
      .addCase(updateSequenceStep, (state, action) => {
        return getNextState(state, action.payload.fileCollections);
      })
      .addCase(deleteSequenceStep, (state, action) => {
        const sequenceStepID = action.payload.stepID;
        const keys = state.bySequenceStepID[sequenceStepID] || [];
        keys.forEach((key) => {
          delete state.byID[key];
        });
        delete state.bySequenceStepID[sequenceStepID];
      })
      .addDefaultCase(() => {});
  },
});

function formatSequenceStepFileCollection({
  fileCollection,
  ...sequenceStepFileCollection
}: DecoratedSequenceStepFileCollection) {
  return sequenceStepFileCollection;
}

function getNextState(
  state: SequenceStepFileCollectionsState,
  sequenceStepFileCollections: DecoratedSequenceStepFileCollection[]
) {
  let byID = { ...state.byID };
  let bySequenceStepID = { ...state.bySequenceStepID };
  sequenceStepFileCollections.forEach((ssfc) => {
    const key = buildSequenceStepFileCollectionKey(
      ssfc.sequenceStepID,
      ssfc.fileCollectionID
    );
    byID[key] = formatSequenceStepFileCollection(ssfc);
    bySequenceStepID[ssfc.sequenceStepID] = addToIDArray(
      bySequenceStepID[ssfc.sequenceStepID],
      key
    );
  });
  return { byID, bySequenceStepID };
}

export function buildSequenceStepFileCollectionKey(
  sequenceStepID: number,
  fileCollectionID: number
) {
  return `${sequenceStepID}.${fileCollectionID}`;
}

export const {
  setSequenceStepFileCollections,
  addSequenceStepFileCollection,
  deleteSequenceStepFileCollection,
} = sequenceStepFileCollectionsSlice.actions;

export default sequenceStepFileCollectionsSlice.reducer;
