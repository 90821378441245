import Breadcrumbs, { Crumb } from '@/components/Breadcrumbs';
import { type FileCollectionPath } from './utils';

export type { FileCollectionPath };

export type FileCollectionCrumbsProps = {
  className?: string;
  homeValue?: number | undefined;
  crumbs: FileCollectionPath[];
  onSelect: (id: number | undefined) => void;
};

export default function FileCollectionCrumbs({
  className = '',
  homeValue = undefined,
  crumbs,
  onSelect,
}: FileCollectionCrumbsProps) {
  const crumbArray = [
    <Crumb key="home" label="Home" value={homeValue} />,
    ...crumbs.map((crumb) => (
      <Crumb
        key={crumb.id}
        label={crumb.fileCollection.name}
        value={crumb.id}
      />
    )),
  ];
  if (crumbArray.length === 1) {
    // Ensures a trailing divider after Home
    crumbArray.push(<Crumb key="empty" label="" value={undefined} />);
  }
  return (
    <Breadcrumbs<number | undefined> className={className} onChange={onSelect}>
      {crumbArray}
    </Breadcrumbs>
  );
}
