export const classes = {
  wrapper: 'h-14 w-full grow-0 shrink-0',
  content:
    'flex items-center justify-between opacity-100 h-full w-full transition-all duration-200 bg-white pl-4 pr-0 border-b border-grey-3',
  labelWrapper: 'flex items-center justify-start',
  typeIcon: 'text-grey-7 mr-3',
  label:
    'text-grey-8 text-base overflow-hidden whitespace-nowrap overflow-ellipsis',
  optionsWrapper: 'flex items-center',
  icon: 'text-2xl text-grey-7',
  delete: 'text-red-2',
};
