import { useEffect, useState } from 'react';
import type { UndecoratedSequence } from '@witmetrics/api-client';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { useDispatch, useSequence } from '@/store/useStore';
import { updateSequence as dispatchUpdateSequence } from '@/store/slices/sequencesSlice';
import TextField from '@/components/TextField';
import { showSuccessToaster } from '@/utils/toasters';
import { classes, isDisabled, updateSequence, validateFields } from './utils';

type SequenceDialogProps = {
  sequenceID: number;
  onSave?: (sequence: UndecoratedSequence) => void;
  onClose: () => void;
};

export default function SequenceDialog({
  sequenceID,
  onSave,
  onClose,
}: SequenceDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const sequence = useSequence(sequenceID);

  useEffect(() => {
    if (sequence) fillFields();
  }, [sequence]);

  const fillFields = () => {
    setName(sequence!.name);
    setDescription(sequence!.description || '');
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalid = validateFields(name);
      if (invalidFields.length > 0) return setInvalidFields(invalid);
      const updates = await updateSequence(sequenceID, name, description);
      dispatch(dispatchUpdateSequence(updates));
      showSuccessToaster('Sequence updated');
      toggleSaving(false);
      if (onSave) onSave(updates);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving sequence');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Update file sequence" onClose={onClose} />
      <DialogBody className={classes.body}>
        <TextField
          error={invalidFields.includes('name')}
          className={classes.input}
          name="name"
          label="Name"
          value={name}
          onChange={({ name }) => setName(name)}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          className={classes.input}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={({ description }) => setDescription(description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(name)}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
