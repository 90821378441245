import { uploadFile } from '@/utils/files';

export const classes = {
  wrapper: 'border-b border-grey-4 pt-2 pb-2',
  titleWrapper: 'flex justify-between items-center pl-4 pr-2',
  description: 'text-sm text-grey-7 px-4 pb-2',
};

export async function uploadFiles(files: File[], accountID: number) {
  return await Promise.all(files.map((file) => uploadFile(file, accountID)));
}
