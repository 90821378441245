import { linearProgressClasses } from '@mui/material/LinearProgress';
import { theme } from '@/utils/theme';

export const styles = {
  progressBar: {
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette['grey-2'].main,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      transition: 'transform 100ms linear',
      backgroundColor: theme.palette['purple-2'].main,
    },
  },
};
