import { type DragEventHandler, useEffect, useRef, useState } from 'react';
import type {
  DecoratedFileCollection,
  DecoratedSequenceStep,
  UndecoratedSequenceStep,
} from '@witmetrics/api-client';
import Dialog from '@/components/Dialog';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  downloadFileVersion,
  fetchFileVersion,
  isScan,
  isViewableNonScan,
} from '@/utils/files';
import { useDispatch, useSequence, useSequenceSteps } from '@/store/useStore';
import { setSequenceSteps as dispatchSetSequenceSteps } from '@/store/slices/sequenceStepsSlice';
import { setFileCollectionFiles as dispatchSetFileCollectionFiles } from '@/store/slices/fileCollectionFilesSlice';
import {
  addFileCollection as dispatchAddFileCollection,
  setFileCollections as dispatchSetFileCollections,
} from '@/store/slices/fileCollectionsSlice';
import { setFiles as dispatchSetFiles } from '@/store/slices/filesSlice';
import { setSequenceStepFiles as dispatchSetSequenceStepFiles } from '@/store/slices/sequenceStepFilesSlice';
import { addSequenceStepFileCollection as dispatchAddSequenceStepFileCollection } from '@/store/slices/sequenceStepFileCollectionsSlice';
import { setFileVersions as dispatchSetFileVersions } from '@/store/slices/fileVersionsSlice';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { showErrorToaster } from '@/utils/toasters';
import { loadScan } from '@/utils/scans';
import ViewerHeader from '@/components/ViewerHeader';
import ProgressBar from '@/components/ProgressBar';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import StepViewer from './StepViewer';
import SequenceIcon from '@/icons/SequenceIcon';
import SceneProvider from '@/providers/SceneProvider';
import SequenceDialog from '@/dialogs/SequenceDialog';
import DeleteSequenceDialog from '@/dialogs/DeleteSequenceDialog';
import SequenceSlider from './SequenceSlider';
import FileDropOverlay, {
  type FileDropOverlayProps,
} from '@/components/FileDropOverlay';
import { mapToKey } from '@/utils/arrays';
import { formatPercentage } from '@/utils/strings';
import {
  addCollectionToStep,
  addFileToStep,
  classes,
  createFileCollectionFile,
  fetchSequenceSteps,
  getOrderedIDs,
} from './utils';

export type SequenceViewerDialogProps = {
  sequenceID: number;
  onDelete?: () => void;
  onClose: () => void;
};

export default function SequenceViewerDialog({
  sequenceID,
  onDelete,
  onClose,
}: SequenceViewerDialogProps) {
  const stepsRef = useRef<UndecoratedSequenceStep[]>([]);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const { loadedFiles, addLoadedFile } = useLoadedFiles();
  const [activeStepID, setActiveStepID] = useState<number | null>(null);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [isLoadingFiles, toggleLoadingFiles] = useToggle(false);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isEditing, toggleEditing] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);
  const [isFileDropOverlayShown, toggleFileDropOverlay] = useToggle(false);
  const sequence = useSequence(sequenceID);
  const steps = useSequenceSteps(sequenceID);

  useEffect(() => {
    fetchData();
  }, [sequenceID]);

  useEffect(() => {
    // If a step has been removed, update activeStepID
    if (steps.length < stepsRef.current.length) {
      const stepIDs = getOrderedIDs(steps);
      const oldIDs = getOrderedIDs(stepsRef.current);
      if (stepIDs.length === 0) setActiveStepID(null);
      else if (activeStepID !== null && !stepIDs.includes(activeStepID)) {
        // Current step was removed, find the next step
        const index = oldIDs.indexOf(activeStepID);
        if (index < steps.length) setActiveStepID(stepIDs[index]);
        else setActiveStepID(stepIDs[steps.length - 1]);
      }
    }
    stepsRef.current = steps;
  }, [steps, activeStepID]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchSequenceSteps(sequenceID);
      dispatch(dispatchSetSequenceSteps(data.steps));
      dispatch(dispatchSetFileCollectionFiles(data.fileCollectionFiles));
      dispatch(dispatchSetFileCollections(data.fileCollections));
      dispatch(dispatchSetFiles(data.files));
      // TODO: Also handle loading file collections
      if (data.files.length > 0) {
        await handleOpenFiles(data.files.map((f) => f.id));
      }
      // Only set activeStepID once all files finished loading
      if (data.steps.length > 0) setActiveStepID(data.steps[0].id);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file sequence');
    }
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (!activeStepID) return;
    toggleFileDropOverlay(true);
  };

  const handleOpenFiles = async (fileIDs: number[]) => {
    try {
      let fileIDsToLoad = fileIDs.filter((id) => !loadedFiles[id]);
      if (fileIDsToLoad.length === 0) return;
      toggleLoadingFiles(true);
      setLoadingProgress(0);
      let count = 0;
      const total = fileIDsToLoad.length;
      for (const fileID of fileIDsToLoad) {
        const fileVersion = await fetchFileVersion(fileID);
        if (!fileVersion) {
          showErrorToaster('File version not found');
          continue;
        }
        const arrayBuffer = await downloadFileVersion(fileVersion.id);
        if (isScan(fileVersion.name)) {
          const mesh = loadScan(fileVersion.name, arrayBuffer);
          if (!mesh) {
            showErrorToaster('Unrecognized scan format');
            continue;
          }
          addLoadedFile(fileID, mesh);
        } else if (isViewableNonScan(fileVersion.name)) {
          addLoadedFile(fileID, URL.createObjectURL(new Blob([arrayBuffer])));
        }
        count++;
        setLoadingProgress((val) => Math.max(val, count / total));
      }
      toggleFetchingData(false);
      toggleLoadingFiles(false);
    } catch (err) {
      toggleFetchingData(false);
      toggleLoadingFiles(false);
      onApiError(err, 'Error loading file(s)');
    }
  };

  const handleEdit = () => toggleEditing(true);

  const handleCloseEditDialog = () => toggleEditing(false);

  const handleStartDelete = () => toggleDeleteDialog(true);

  const handleCloseDeleteDialog = () => toggleDeleteDialog(false);

  const handleCloseFileDropOverlay = () => toggleFileDropOverlay(false);

  const handleDeleteSequence = (sequenceID: number) => {
    handleCloseDeleteDialog();
    if (activeStepID === sequenceID) setActiveStepID(null);
    if (onDelete) onDelete();
    onClose();
  };

  const handleAddStep = (step: DecoratedSequenceStep) => {
    setActiveStepID(step.id);
  };

  const handleSelectStep = (stepID: number) => setActiveStepID(stepID);

  const handleSaveFiles: FileDropOverlayProps['onSaveFiles'] = async (
    fileUploads,
    parentCollectionID
  ) => {
    const files = mapToKey(fileUploads, 'file');
    const fileVersions = mapToKey(fileUploads, 'fileVersion');
    dispatch(dispatchSetFiles(files));
    dispatch(dispatchSetFileVersions(fileVersions));
    if (parentCollectionID) {
      const fileCollectionFiles = await Promise.all(
        files.map((f) => createFileCollectionFile(parentCollectionID, f.id))
      );
      dispatch(dispatchSetFileCollectionFiles(fileCollectionFiles));
    } else {
      const stepFiles = await Promise.all(
        files.map((f) => addFileToStep(activeStepID!, f.id))
      );
      dispatch(dispatchSetSequenceStepFiles(stepFiles));
    }
  };

  const handleSaveFileCollection = async (
    fileCollection: DecoratedFileCollection
  ) => {
    dispatch(dispatchAddFileCollection(fileCollection));
    if (!fileCollection.parentFileCollectionID) {
      const stepCollection = await addCollectionToStep(
        activeStepID!,
        fileCollection.id
      );
      dispatch(dispatchAddSequenceStepFileCollection(stepCollection));
    }
  };

  return (
    <>
      <Dialog
        className={classes.dialog}
        onDragEnter={handleDragEnter}
        onClose={onClose}>
        {sequence && (
          <>
            <ViewerHeader
              label={sequence.name}
              renderIcon={(iconClassName) => (
                <SequenceIcon className={iconClassName} />
              )}
              onEdit={handleEdit}
              onDelete={handleStartDelete}
              onClose={onClose}
            />
            <SceneProvider backgroundColor={0xf3f5f7 /*bg-grey-2*/}>
              {isLoadingFiles && (
                <div className={classes.progressWrapper}>
                  <div className={classes.progressLabel}>
                    Loading sequence
                    <LoopingEllipsis />
                  </div>
                  <div className={classes.progressLabel}>
                    {formatPercentage(loadingProgress, 0)}
                  </div>
                  <ProgressBar
                    className={classes.progressBar}
                    progress={100 * loadingProgress}
                  />
                </div>
              )}
              {activeStepID && (
                <StepViewer
                  isLoading={isFetchingData || isLoadingFiles}
                  className={classes.viewer}
                  stepID={activeStepID}
                  onSaveFiles={handleSaveFiles}
                  onSaveFileCollection={handleSaveFileCollection}
                  onOpenFiles={handleOpenFiles}
                />
              )}
            </SceneProvider>
            <SequenceSlider
              sequenceID={sequenceID}
              activeStepID={activeStepID}
              stepCount={steps.length}
              onAddStep={handleAddStep}
              onSelect={handleSelectStep}
            />
          </>
        )}
      </Dialog>
      {isEditing && (
        <SequenceDialog
          sequenceID={sequenceID}
          onClose={handleCloseEditDialog}
        />
      )}
      {isDeleteDialogShown && (
        <DeleteSequenceDialog
          sequenceID={sequenceID}
          onDelete={handleDeleteSequence}
          onClose={handleCloseDeleteDialog}
        />
      )}
      {isFileDropOverlayShown && (
        <FileDropOverlay
          open
          onSaveFiles={handleSaveFiles}
          onSaveFileCollection={handleSaveFileCollection}
          onClose={handleCloseFileDropOverlay}
        />
      )}
    </>
  );
}
