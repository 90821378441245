import Skeleton from '@mui/material/Skeleton';
import { classes } from '../utils';
import { styles } from './utils';

export type SkeletonDirectoryBlockProps = {
  delay?: number;
};

export default function SkeletonDirectoryBlock({
  delay = 0,
}: SkeletonDirectoryBlockProps) {
  return (
    <Skeleton
      sx={styles.skeleton(delay)}
      className={classes.wrapper}
      variant="rectangular"
      animation="wave"
    />
  );
}
