import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { UndecoratedSequence } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import {
  addFileCollectionSequence,
  setFileCollectionSequences,
} from './fileCollectionSequencesSlice';

type SequencesState = {
  byID: Record<string, UndecoratedSequence>;
};

const initialState = {
  byID: {},
} satisfies SequencesState as SequencesState;

export const sequencesSlice = createSlice({
  name: 'sequences',
  initialState,
  reducers: {
    setSequences: (state, action: PayloadAction<UndecoratedSequence[]>) => {
      return getNextState(state, action.payload);
    },
    addSequence: (state, action: PayloadAction<UndecoratedSequence>) => {
      return getNextState(state, [action.payload]);
    },
    updateSequence: (state, action: PayloadAction<UndecoratedSequence>) => {
      return getNextState(state, [action.payload]);
    },
    deleteSequence: (state, action: PayloadAction<{ sequenceID: number }>) => {
      const key = `${action.payload.sequenceID}`;
      if (!state.byID[key]) return state;
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => ({ byID: {} }))
      .addCase(setFileCollectionSequences, (state, action) => {
        return getNextState(
          state,
          action.payload.map((fcs) => fcs.sequence)
        );
      })
      .addCase(addFileCollectionSequence, (state, action) => {
        return getNextState(state, [action.payload.sequence]);
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(state: SequencesState, sequences: UndecoratedSequence[]) {
  let byID = { ...state.byID };
  sequences.forEach((s) => {
    byID[`${s.id}`] = s;
  });
  return { byID };
}

export const { setSequences, addSequence, updateSequence, deleteSequence } =
  sequencesSlice.actions;

export default sequencesSlice.reducer;
