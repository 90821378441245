// https://stackoverflow.com/a/52173021/5839360: TypeScript has a restriction that an interface can only extend a dotted name, so you have to define a type alias
type DivProps = Omit<
  JSX.IntrinsicElements['div'],
  'value' | 'onChange' | 'label'
>;
export interface CrumbProps<T> extends DivProps {
  label: string;
  value: T;
}

export default function Crumb<T>({ label, value, ...props }: CrumbProps<T>) {
  return <div {...props} />;
}
