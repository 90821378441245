import IconButton from '@/components/IconButton';
import Tooltip from '@/components/Tooltip';
import AddIcon from '@/icons/AddIcon';
import { classes } from './utils';

export type StepDividerProps = {
  onAddStep: () => void;
};

export default function StepDivider({ onAddStep }: StepDividerProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.divider} />
      <Tooltip title="Add step">
        <IconButton onClick={onAddStep}>
          <AddIcon className={classes.addIcon} />
        </IconButton>
      </Tooltip>
      <div className={classes.divider} />
    </div>
  );
}
